//
// Button Mixins
// --------------------------------------------------
//
$button-background-default: palette(brand);
$button-color-default:      palette(white);

$button-font:           $font-family--body;
$button-font-size:      rem-calc(18);
$button-font-weight:    $font-weight--bold;
$button-letter-spacing: $letter-spacing--small;
$button-border-radius:  6px;
$button-padding:        rem-calc(14 30);



@mixin button($background: $button-background-default, $color: $button-color-default) {
  color: $color;
  background: $background;
  font-family: $button-font;
  font-size: 16px;
  font-weight: $button-font-weight;
  letter-spacing: $button-letter-spacing;
  line-height: 1em;
  text-align: center;
  padding: 18px 24px;
  position: relative;
  border: 2px solid $background;
  border-radius: $button-border-radius;
  min-width: rem-calc(200);
  width: 100%;

  box-sizing: border-box;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  transition-property: background-color, color, border;
  transition-duration: .25s;
  transition-timing-function: $transitions--easeOutSine;
  appearance: none;

  @include from(lp) {
    min-width: rem-calc(248);
    width: auto;
  }

  @include from(pt) {
    font-size: $button-font-size;
    padding: $button-padding;
  }

  @include from(d) {
    padding: $button-padding;
  }

  &:hover {
    background-color: $color--hover;
    color: $color--text;
    border: 2px solid $color--hover;

    svg {
      fill: $color--text;
    }
  }

  &:focus {
    outline: none;  
  }

  &.white {
    background-color: palette(white);
    color: $color--text;
    border-color: palette(white);

    &:hover {
      background-color: transparent;
      color: palette(white);
      border-color: palette(white);
    }

    &.transparent {
      background-color: transparent;
      color: palette(white);
      border-color: $color;

      &:hover {
        background-color: $color;
        color: palette(black);
        border-color: $color;
      }
    }
  }

  &.invert {
    background-color: $background;
    border-color: $color;

    &:hover {
      background-color: $color;
      color: $background;
    }
  }
  .gfield--fileupload &,
  &.ghost,
  &.transparent {
    background-color: transparent;
    border-color: $background;
    color: $color--text;

    svg {
      fill: $color--text;
    }

    &:hover {
      background-color: $background;
      color: $color;
      border-color: $background;

      svg {
        fill: $color;
      }
    }
  }

  &.chat-trigger-btn {
    border-radius: 0px;
    border-top-left-radius: $button-border-radius;
    right: 0;
    bottom:0;
    position: fixed;
    height: 36px;
    width: 180px;
    padding:0 15px;
    display: inline-flex;
    z-index: 1000;

    font-size: 16px;
  }

  svg {
    fill: #fff;
    left: rem-calc(10);
    margin-right: rem-calc(5);
    height: rem-calc(20);
    width:  rem-calc(20);
    @include transition(fill);

    @include from(d) {
      margin-right: rem-calc(10);
    }
  }


  .icon--shop-home,
  .icon--shop-commercial {
    margin-right: 0;
    margin-left: rem-calc(5);
    height: rem-calc(20);
    width:  rem-calc(20);
    @include from(d) {
      margin-left: rem-calc(10);
    }
  }

  .icon--shop-home {
    height: rem-calc(24);
    width: rem-calc(28);
  }

  .icon--shop-commercial {
    height: rem-calc(24);
    width: rem-calc(26);
  }

}
