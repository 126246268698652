//
// Typography
// --------------------------------------------------

html {
  color: $color--text;
  line-height: $line-height--base;
  font-family: $font-family--body;
  font-size: 14px;
  font-weight: $font-weight--body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include from(pt) {
    font-size: 16px;
  }

  @include from(d) {
    font-size: 18px;
  }

  @include from(ld) {
    font-size: $font-size--base;
  }

  @include from(vld) {
    font-size: 22px;
  }

  &.lt-ie9 {
    font-size: $font-size--base;
  }
}



// Links

a {
  text-decoration: none;
}


// Headings
h1, h2, h3, h4, h5, h6, p, ul, ol, span {
  font-variant-ligatures: common-ligatures;
  font-feature-settings: 'liga', 'clig';
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: rem-calc($spacer--4);
  &:last-child:not(.heading):not(.sub-heading):last-child:not(.hero__heading){
   margin-bottom: 0;
  }
}

h1, .alpha,
h2, .beta,
h3, .gamma,
h4, .delta,
h5, .epsilon,
h6, .zeta {
  font-family: $font-family--heading;
  font-weight: $font-weight--heading;
  line-height: 1.2;
}

h1, .alpha {
  @include font-styles--alpha();
}

h2, .beta {
  @include font-styles--beta();
}

h3, .gamma {
  @include font-styles--gamma();
}

h4, .delta {
  @include font-styles--delta();
}

h5, .epsilon {
  @include font-styles--epsilon();
}

h6, .zeta {
  @include font-styles--zeta();
}

hr {
  border: 0;
  height: 1px;
  margin: rem-calc(25 0);
}

// Paragraphs
p,
%p {
  word-break: normal;
  word-wrap: normal;

  .summary & {
    @include font-styles--body-copy();
  }
}

li {
  @include font-styles--body-copy();
}

strong {
  font-weight: 700;
}

pre {
  font-size: rem-calc(11);
}

q {
  font-style: italic;
}



// Marks
mark,
.marker {
  background: palette(brand);
  color: #fff;
  border-radius: rem-calc(10);
  padding: .1em .5em;
  font-size: rem-calc(12);
  text-transform: capitalize;
}
