.icon {
  &--checkmark {
    height: 90%;
    width:  90%;
  }

  &--plus {
    height: 80%;
    width:  80%;
  }

  &--minus {
    height: 80%;
    width:  80%;
  }
}