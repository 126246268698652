.content-block-404 {
  &.content-block{
    position: relative;

    &:before {
      @include overlay(0.45, #000);
      z-index: 1;
    }
  }

  .heading,
  .large-type {
    color: palette(white);
  }

  .large-type {
    @include large-text();
  }
}