.page-nav {
  display: none;

  @include from(sd) {
    display: block;
    position: fixed;
    height: 100vh;
    top:0;
    left: 0;
    @include add-border-frame-property($spacer--frame-width, 'width');
    max-height: rem-calc(1080);
    z-index: 200;
  }

  &__list,
  &__list-item {
    margin: 0;
    padding: 0;
  }

  &__list {
    display:         flex;
    flex-flow:       column;
    justify-content: center;
    align-items:     center;
    height: 100%;
    width: 100%;

  }

  &__list-item {
    display: block;
    cursor: pointer;
    line-height: 1em;
    list-style: none;
    text-align: center;
    width: 100%;
    margin-top: rem-calc($spacer--1);

    &:first-child{
      margin-top: 0;
    }
  }

  &__list-link {
    display: block;
    border-radius: 1000px;
    height: 8px;
    width: 8px;
    margin: 0 auto;

    background-color: $color--border;
    line-height: 1em;

    &.js-section-triggered {
      background-color: #8A8E90;
    }
  }
}

.scroll-down {
  position: absolute;
  bottom: 0;
  left: 0;
  display:         flex;
  flex-flow:       row;
  justify-content: center;
  align-items:     center;
  opacity: 1;
  @include add-border-frame-property($spacer--frame-width, 'height');
  cursor: pointer !important;

  transform-origin: 0% 0%;
  transform: rotate(90deg) translateY(-100%) translateX(-100%);
  @include transition(transform, 0.25s)

  color: #A8A8A8;
  text-align: right;
  font-size: rem-calc(12);
  font-weight: $font-weight--bold;
  letter-spacing: 0.180em;
  line-height: 1em;
  text-transform: uppercase;
  white-space: nowrap;


  &.js-main-visible {
    transform: rotate(90deg) translateY(0%) translateX(-100%);
  }

  svg {
    display: block;
    width: rem-calc(28);
    height: rem-calc(14);
    margin-left: rem-calc(5);
    fill: #A8A8A8;
    max-height: none;
    max-width: none;
  }
}