//
// Mobile Navigation
// --------------------------------------------------
$tiny_phone: 362px;

.nav--mobile {
  margin:  0;
  padding: 0;

  ul {
    margin:  0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  &--primary {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top:   0;
    right: 0;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: transform .4s $ease-out-cubic;
    width:  100%;
    height: 100%;
    z-index: 1001;
    background: palette(white);
    border-left: 1px solid palette(grey, light);
    padding: 100px 16px 50px;

    @include breakpoint($tiny_phone) {
      padding: 100px 32px 50px;
    }

    @include from(lp) {
      width: 60%;
    }

    @include from(lt) {
      width: 440px;
    }

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translateZ(0);
      transition: transform .3s, opacity .3s;
      will-change: transform, opacity;
    }

    .no-js & {
      position: relative;
      height: auto;
      width: 100%;
      overflow: visible;
      visibility: visible;
      z-index: 2;
    }
  }

  &__meta-menu {
    padding-top: $spacer--3 + px;

    .nav__list-item {

    }

    .nav__list-link {
      display: block;
      padding: rem-calc(12 0);

      color: #A8A8A8;
      font-size: 15px;
      font-weight: $font-weight--bold;
      line-height: 1em;
    }
  }


  &__contact {
    padding-top: rem-calc(30);

    .contact-info {
      text-align: left;

      h4 {
        font-size: rem-calc(18);
        letter-spacing: 0;
      }

      > ul {
        margin-bottom: 0;

        li {
          padding: rem-calc(10 0);
        }
      }
    }
  }

  &__buttons {
    text-align: center;
    display:         flex;
    flex-flow:       row wrap;
    justify-content: center;
    align-items:     stretch;
    padding-top: $spacer--3 + px;

    @include from(pt) {
      padding-top: $spacer--10 + px;
    }

    .button {
      //display: block;
      min-width: 0;
      max-width: 100%;
      width: 100%;
    }

    .button--half-width {
      margin-bottom: rem-calc($spacer--1);
      width: 50%;
      padding-left: rem-calc(5);
      padding-right: rem-calc(5);

      @include from(pt) {
        margin-bottom: rem-calc($spacer--2);
      }

      &:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 1px solid #0178AA;
      }

      &:nth-child(2) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }

  &__social {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     center;
    padding-top: $spacer--3 + px;

    .social-media__link {
      margin-left: rem-calc($spacer--1);

      &:first-child {
        margin-left: 0;
      }
    }

    svg {
      width: $spacer--3 + px;
      height: $spacer--3 + px;
    }
  }

  .nav--mobile__footer {
  }
}

// Pretty facking menu
// @TODO: Update class structure to indicate mobile variant
.menu {
  background: palette(white);
  position: relative;
  right: 0;
  z-index: 20000;
  width: 100%;

  &__wrap {
    position: absolute;
    top:    0;
    bottom: 0;
    width: 100%;
  }

    &__level {
      background: none;
      position: absolute;
      top:  0;
      left: 0;
      overflow: hidden;
      list-style-type: none;
      height: auto;
      visibility: hidden;
      margin:  0;
      padding: 0;
      width: 100%;

      &--current {
        visibility: visible;
      }
    }

      &__item {
        display: block;
        margin: 0;

        .animate-outToRight & {
          animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
        }
        .animate-outToLeft & {
          animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
        }
        .animate-inFromLeft & {
          animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
        }
        .animate-inFromRight & {
          animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
        }
      }

        &__link {
          display: inline-flex;
          flex-flow:       row;
          justify-content: flex-start;
          align-items:     center;
          color: $color--text;
          position: relative;
          padding: rem-calc(12 0);
          line-height: 1em;
          font-family: $font-family--body;
          font-size: 18px;
          font-weight: $font-weight--bold;
          text-transform: none;
          transition: color 0.1s;
          position: relative;
          z-index: 1;


          .icon--arrow {
            height: 12px;
            width: 24px;
            margin-left: 8px;
          }

          &:hover,
          &[data-submenu]:hover::after {
            color: palette(brand);
          }
        }


  &__back {
    background: none;
    cursor: pointer;
    border: 0;
    bottom: 100%;
    left: -6px;
    display: block;
    color: palette(brand);
    font-size: rem-calc(20);
    font-weight: $font-weight--bold;
    line-height: 1em;
    margin-bottom: rem-calc(0 0 3);
    padding: rem-calc(15 0);
    position: absolute;
    opacity: 1;
    transform: translateX(0px);
    transition: transform 0.25s $ease-out-cubic, opacity 0.25s $ease-out-cubic;
    z-index: 100;

    &:hover,
    &:focus {
      color: palette(brand);
      outline: none;
    }

    &.is-hidden {
      pointer-events: none;
      opacity: 0;
      transform: translateX(20px);
    }
  }

  &__breadcrumbs {
    position: absolute;
    bottom: 100%;
    left:     0;
    font-size: rem-calc(10);
    line-height: 1em;
    padding: rem-calc(30 0 0);
    height: rem-calc(40);

    a {
      cursor: pointer;
      display: inline-block;
      color: palette(brand);
      vertical-align: top;
      font-size: rem-calc(10);
      font-weight: $font-weight--bold;
      letter-spacing: $letter-spacing--large;
      line-height: 1em;
      text-transform: uppercase;
      margin-right:   rem-calc(3);
      padding-right: rem-calc(13);
      position: relative;

      &,
      &:hover {
        color: palette(brand);
      }

      &:last-child {
        pointer-events: none;
      }

      &:not(:last-child) {
        &::after {
          @extend %right;
          @include icon;
          color: palette(brand);
          display: inline-block;
          line-height: rem-calc(10);
          padding: 0;
          position: absolute;
          top:   1px;
          right: 0;
        }

        &:hover::after {
          color: #33353e;
        }
      }
    }

    .breadcrumb-link {
      opacity: 1;
      transition-property: opacity transform;
      transition-duration: 0.25s;
      transition-timing-function: easeOutCubic;
      transform: translateX(0px);

      &.not-visible {
        opacity: 0;
        transform: translateX(20px);

        &:first-child {
          opacity: 1;
          transform: translateX(0px);
        }
      }
    }
  }
}

[class^='animate-'],
[class*=' animate-'] {
  visibility: visible;
}