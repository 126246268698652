//
// Listing
// --------------------------------------------------

.listing {
  flex: 1 1 100%;
  @include add-vertical-spacing($spacer--8, margin, top);

  @include from(lp) {
    flex: 0 1 calc(50% - #{rem-calc($spacer--4)};
  }

  &:first-child {
    flex: 1 1 100%;
    margin-top: 0;

    @include from(lp) {
      .listing__content {
        display:         flex;
        flex-flow:       column;
        justify-content: flex-start;
        align-items:     flex-start;
      }

      .post-meta {
        order: 2;
      }

      .heading {
        @include font-styles--beta();
        margin-bottom: rem-calc($spacer--1);
      }

      .summary {
        display: none;
      }
    }
  }

  &__thumb-image {
    position: relative;
    width: 100%;
    margin-bottom: rem-calc($spacer--3);

    &:before {
      display: block;
      content: '';
      padding-bottom: 56.25%;
      width: 100%;
    }

  }

  .post-meta {
    margin-bottom: rem-calc($spacer--1);
  }

  .heading {
    @include font-styles--gamma();
    line-height: 1.125em;
    margin-bottom: rem-calc($spacer--1);
  }

  .summary {
    @include font-styles--body-copy();
    color: $color--text;
  }
}