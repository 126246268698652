//
// Pace
// --------------------------------------------------

html:not(.site-loader) {

  .pace {
    pointer-events: none;
    user-select: none;
  }

  .pace-inactive {
    display: none;
  }

  .pace .pace-progress {
    background: #29d;
    position: fixed;
    top:     0;
    right: 100%;
    height: 2px;
    width: 100%;
    z-index: 2000;
  }

  .pace .pace-progress-inner {
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    display: block;
    position: absolute;
    right: 0;
    opacity: 1;
    transform: rotate(3deg) translate(0, -4px);
    height: 100%;
    width: 100px;
  }

  .pace .pace-activity {
    display: block;
    border: solid 2px transparent;
    border-top-color:  #29d;
    border-left-color: #29d;
    border-radius: 10px;
    position: fixed;
    top:   15px;
    right: 15px;
    height: 14px;
    width:  14px;
    z-index: 2000;
    animation: pace-spinner 400ms linear infinite;
  }

  @keyframes pace-spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}


// Website loader

html.site-loader {
  //
  $logo-width: 300px;
  $logo-height: 200px;
  //
  $background-width: $logo-width - 10;
  $background-height: $logo-height - 10;
  //

  .pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    z-index: 1501;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 43vw;
    width: 88vw;
    background: #fff;
    border: none;
    overflow: hidden;
    opacity: 0;
    transition: opacity .4s $ease-out-cubic;

    @include from(lp) {
      height: $background-height;
      width: $background-width;
    }
  }

  .pace.pace-active {
    opacity: 1;
  }

  .pace.pace-inactive {
    opacity: 0;
  }

  .pace .pace-progress {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    max-width: rem-calc($background-width);
    position: fixed;
    z-index: 1501;
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    width: 100%;
    background: palette(brand, accent);
  }

  // Page wrapper

  .page-wrapper {
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s linear;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: palette(grey, darker);
    transition: opacity .5s linear;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-logo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity .4s $ease-out-cubic;
    z-index: 1502;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: $logo-width;
      height: $logo-height;
      display: block;
      opacity: 1;
    }

    // svg {
    //   display: block;
    //   height: 45vw;
    //   width: 90vw;
    //   fill: palette(grey, lighter);

    //   @include from(lp) {
    //     width: rem-calc($logo-width);
    //     height: rem-calc($logo-height);
    //   }
    // }
  }

  .pace-done {
    .loading-overlay {
      opacity: 0;
      pointer-events: none;
      transition-delay: 0.3s;
    }

    .loading-logo {

      &,
      img,
      svg {
        opacity: 0;
        pointer-events: none;
        transition-delay: 0.3s;
      }
    }
  }
}