//
// Slider
// --------------------------------------------------

.slider {
  &.content-block.has-heading {
    @include add-vertical-spacing($spacing-unit--vert, padding, bottom);
  }


  &__inner {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: center;
    align-items:     center;

    @include from(lt) {
      flex-flow: row;
      padding-bottom: rem-calc($spacer--8);
    }

    max-width: rem-calc(1650);
    margin: 0 auto;
    width: 100%;
  }

  .slider__column {

    &:first-child {
      flex: 1 1 100%;
      max-width: 100%;
      width: 100%;
      @include add-vertical-spacing($spacing-unit--vert, margin, bottom);

      @include from(lt) {
        flex: 1 1 50%;
        max-width: 50%;
        width: 50%;
        order: 2;
        margin-bottom: 0;
      }
    }
  }

  .swiper-background {
    display: block;
    position: relative;
    width: 100%;

    &:after {
      display: block;
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: rem-calc($spacer--8);
      left: rem-calc($spacer--2);
    }
  }

  &.background-color--dark .swiper-background:after {
    background: palette(white);
  }

  &.background-color--light .swiper-background:after {
    background: palette(blue, lighter);
  }

  .component__content {
    margin-left: 0;
    padding-top: rem-calc($spacer--4);

    @include from(lt) {
      @include add-horizontal-spacing($spacer-unit * 15, padding, right);
      flex: 0 0 rem-calc(510);
      max-width: none;
      padding-top: 0;
    }

    @include from(d) {
      flex: 0 0 rem-calc(630);
    }
  }

  .swiper-container {
    display: block;
    height: 100%;
    width: 100%;
  }

  .swiper-slide {
    .bg-img {
      position: relative;
      &:before {
        content: '';
        display: block;
        padding-bottom: 88.525%;
        width: 100%;
      }
    }
  }

  img,
  a:not(.button):not(.btn):not(.text-link), {
    display: block;
  }

  img {
    max-width: none;
    width: 100%;
  }

  .swiper-navigation {
    position: absolute;
    top: 100%;
    right: 0;
    display: inline-flex;
    flex-flow:       row;
    align-items: center;
    justify-contents: center;
    height: rem-calc($spacer--8);
    padding-right: rem-calc($spacer--3);
  }

  .swiper-prev,
  .swiper-next {
    fill: $color--text;
    @include transition(fill, 0.3s);

    cursor: pointer;
    line-height: 0;
    z-index: 1;
    position: relative;
    top: 1px;

    &:hover {
      fill: palette(brand);
    }

    &.swiper-button-disabled {
      fill: palette(white);
      opacity: .5;
    }
  }

  .swiper-next,
  .swiper-prev {
    svg {
      display: block;
      width: rem-calc(30);
      height: rem-calc(15);
      max-height: none;
      max-width: none;
    }
  }
  .swiper-prev {
    transform: scaleX(-1);
  }

  .swiper-pagination {
    position: static;
    margin-top: 0;
    padding: rem-calc(0 $spacer--1);
    font-size: rem-calc(15);
    font-weight: $font-weight--bold;
  }
}
