//
// Forms
// --------------------------------------------------

input[type='tel'],
input[type='text'],
input[type='email'],
input[type='number'],
textarea,
select {
  appearance: none;
  // @TODO: Replace colors with variables?
  background-color: palette(blue, light);
  border: 0;
  border-radius: 3px;
  box-sizing: border-box;
  color: rgba(0,0,0,0.5);
  display: block;
  padding: rem-calc(25);
  font-family: $font-family--heading;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  resize: none;
  outline: 0;
  width: 100%;

  @include from(lp) {
    font-size: rem-calc(18);
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
    @include from(lp) {
      font-size: rem-calc(18);
    }
  }

  .gfield_error & {
    background-color: palette(red, error);
    box-shadow: inset 0px 0px 2px palette(red);
  }
}

select {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.8 14.4"><path fill="none" stroke="#231f20" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" d="M.9.9L11 13.5 20.9.9"/></svg>') no-repeat calc(100% - 20px) center palette(blue, light);
  background-size: 15px;
  padding-right: 40px;

  option {
    font-size: 16px;
  }
}

select::-ms-expand {
  display: none;
}

textarea {
  color: $color--text;
  height: rem-calc(140);

  .gform_wrapper & {
    color: $color--text;
  }
}

input[type='search'] {
  appearance: none;
  vertical-align: middle;
  resize: none;
  width: 100%;
  padding: rem-calc($spacer--1 0);

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #a8a8a8;
  }
}

input[type='submit'] {
  @include button;

  &:focus {
    outline: none;
  }
}

.search-submit {
  background: transparent;
  border: 0;
  display: block;
  padding: 0;
  height: 100%;
  width:  100%;

  .icon-search-find {
    color: palette(grey, light);
  }
}



// Gravity Forms

.gform_ajax_spinner {
  position: absolute;
  top: 50%;
  width: rem-calc(30);
  height: rem-calc(30);
  margin-top: rem-calc(-15);
  margin-left: rem-calc(10);
  left: 100%;
  animation:spinner 1s $transitions--base infinite;
}

.gform_validation_container {
  display: none;
}

.gform_fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gfield {
  border: 0;
  margin: rem-calc(0 0 $spacer--3);
  padding: 0;
  list-style-type: none;
  list-style-image: none;
  width: 100%;

  li {
    list-style-type: none;
    list-style-image: none;
  }

  &--form {
    margin-top: rem-calc($spacer--3);
    margin-bottom: rem-calc($spacer--3);
  }

  &--address {
    margin-bottom: 0;
  }
}

.gfield.half-col,
.name_first,
.name_last {
  // @TODO: Update to use flexbox?
  @include pie-clearfix;
  margin: rem-calc(0 0 $spacer--3);
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @include from(lp) {
    width: calc(50% - #{rem-calc($spacer--3)});
  }
}

.name_first,
.name_last {
  @include from(lp) {
    margin-bottom: 0;
  }
}

.ginput_complex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  span[class*="address_"] {
    margin: rem-calc(0 0 $spacer--3);
  }
  @include from(pt) {
    .ginput_full {
      clear: both;
    }

    .ginput_left,
    .ginput_right {
      width: calc(50% - #{rem-calc($spacer--3)});
    }

    .ginput_left {
      float: left;
    }
    .ginput_right {
      float: right;
    }
  }

  .address_line_1 {
    width: 100%;
  }
}

.gclear {
  clear: both;
}

.gfield_radio,
.gfield_checkbox {
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: rem-calc(3 0)
  }

  label {
    display:         inline-flex;
    flex-flow:       row;
    justify-content: center;
    align-items:     center;
    font-size: rem-calc(18);
    font-weight: $font-weight--bold;
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      border-radius: 4px;
      margin-right: rem-calc($spacer--1);
      background: palette(blue, light);
      border: 1px solid $color--border;
    }

    &:after {
      position: absolute;
      content: '';
      display: none;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background: palette(brand);
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
    }
  }

  input[type=checkbox],
  input[type=radio]{
    display: none;

    &:checked + label:after {
      display: block
    }
  }

  .gfield--horizontal &{
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     center;

    li {
      margin-right: rem-calc($spacer--2);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.gform_wrapper {
  .gform_title {
    @extend .beta;
    margin-bottom: rem-calc(40);
  }


  .gsection {
    border-bottom: 0;
    margin-top: rem-calc(20);
  }

  .ginput_complex label,
  .gfield_label {
    //display: none;
    font-family: $font-family--body;
    font-weight: 700;
    font-size: rem-calc(22);
  }

  .show-label {
    .gfield_label {
      display: block;
    }
  }

  .field_sublabel_above {
    .gfield_label_before_complex {
      display: none;
    }
  }

  .validation_message,
  .validation_error {
    font-size: 12px;
    font-weight: 700;
  }

  .validation_error {
    background-color: rgba(#FFCCCC, 0.4);
    border-radius: 3px;
    padding: rem-calc(10);
    color: $color--text;
    margin-bottom: rem-calc(30);
    font-size: rem-calc(16);
    font-weight: $font-weight--regular;
    padding: rem-calc(20 30);
  }

  form {
    .instruction {
      display: none;
    }

    ul {
      margin:  0;
      padding: 0;
    }

    input[type='submit'] {
      @include button;
    }

    .ginput_card_security_code {
      margin-top: rem-calc(10) !important;
    }

    .clear-multi {
      > div {
        display: inline-block;
        margin-right: rem-calc(10);

        &:last-child {
          margin-right: 0;
        }
      }

      input, select {
        margin-top: 0;
      }

      .ginput_container {
        vertical-align: middle;
      }
    }

    .gform_footer {
      display: inline-block;
      position: relative;
      text-align: left;
      margin-top: rem-calc($spacer--2);
    }
  }
}

.gfield--captcha label {
  display: none;
}

.gfield_description {
  font-size: rem-calc(16);
  margin-bottom: rem-calc($spacer--1);
}

.gfield_error {
  position: relative;
}

.gform_confirmation_wrapper {
  line-height: 1.5;
  text-align: center;
}

// Datepicker
.ui-datepicker {
  background-color: palette(white);
  border: none;
  display: none;
  padding: rem-calc(0 0 0);
  z-index: 99999 !important;
  box-shadow: 0 0 10px rgba(#000, 0.15);

  select {
    background-color: palette(grey, lighter);
    border: 0;
    border-radius: rem-calc(8);
    margin-top:    rem-calc(5);
    margin-bottom: rem-calc(5);
    width: 48%;

    &:first-child {
      margin-right: 4%;
    }
  }

  table {
    //border--top: 1px solid palette(grey, lighter);
    border: none;
    margin: 0;
  }

  td,
  th {
    border: 1px solid $color--border;
    text-align: center;
    width: rem-calc(40);
    padding: 0;
    @include transition();

    span,
    a {
      display: block;
      width: 100%;
      font-size: rem-calc(20);
      font-weight: 400;
      text-align: center;
      color: $color--text;
    }
  }

  td {
    &:hover,
    &.ui-datepicker-current-day {

      background-color: palette(brand);
      a {
        color: palette(white);

      }
    }

    &.ui-datepicker-unselectable{
      span{
        opacity: 0.3;
      }
    }
  }
  .ui-datepicker-title {
    display:         flex;
    flex-flow:       row;
    justify-content: center;
    align-items:     center;


  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: $font-weight--regular;
    text-transform: uppercase;
    position: absolute;
    top:0;
    height: 100%;

    &:after{
      @include icon;
      color: palette(brand);
      position: absolute;
      top:  50%;
      display: block;
      line-height: 1em;
      font-size: rem-calc(36);
      margin-top: rem-calc(-16);
      padding: 0;
    }
  }

  .ui-datepicker-prev {
    left: rem-calc(-3);

    &:after {
      @extend %left;
      left: 0;
    }
  }

  .ui-datepicker-next {
    right: rem-calc(-3);

    &:after {
      @extend %right;
      right: 0;
    }
  }

  .ui-datepicker-header {
    margin-bottom: rem-calc(0);
    padding: rem-calc(10 $spacer--4);
    position: relative;
    display: block;

    .ui-datepicker-month,
    .ui-datepicker-year{
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: $letter-spacing--small;
      height: rem-calc($spacer--5);
      padding: 0 rem-calc($spacer--2);
    }

    .ui-icon {
      text-indent: -9999px;
      display: block;
    }
  }

  .ui-datepicker-today {
    background-color: palette(grey, lighter);
  }
}
