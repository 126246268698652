//
// Block Quote
// --------------------------------------------------

.blockquote-block {
  &.content-block {
    @include add-border-frame();
  }

  &__inner {
    position: relative;
    width: 100%;
    @include from(lp) {
      @include add-horizontal-spacing();
      @include add-vertical-spacing(240);
    }
  }
}

.blockquote {
  //@include add-horizontal-spacing();
  //@include add-vertical-spacing();
  @include add-border-frame($spacing-unit--horz/4);
  position: relative;
  z-index: 2;
  background-color: rgba(#E7F1F5, 0.8);
  margin: 0;

  @include from(lp){
    padding: rem-calc($spacer--12 $spacer--8);
  }
  @include from(lt) {
    max-width: rem-calc(860);
  }

  &__content {
    margin-bottom: rem-calc($spacer--4);

    p {
      @include large-text();
      font-family: $font-family--body;
      font-weight: $font-weight--regular;
      line-height: 1.5em;

      @include from(pt) {
        font-weight: $font-weight--regular;
        line-height: 1.5em;
      }
    }

    svg {
      display: block;
      height: rem-calc($spacer--6);
      width: rem-calc($spacer--6);
      margin-bottom: rem-calc($spacer--4);

      fill: palette(white);
    }
  }

  &__author {
    display: block;

    color: palette(grey, dark);
    font-family: $font-family--body;
    font-weight: $font-weight--bold;
    font-size: rem-calc(20);
    font-style: normal;

    &:before {
      content: "\2014";
    }
  }
}