//
// Gallery
// --------------------------------------------------

.gallery {
  &.content-block{
    @include add-border-frame();

    &.has-heading {
      @include add-vertical-spacing($spacing-unit--vert--small, 'padding', 'top');
    }
  }

  .heading {
    @include add-horizontal-spacing;
    text-align: center;
  }

  &__inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &__column {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     stretch;
    width: 100%;

    @include from(lp){
      width: 50%;
    }

    &:first-child {
      .gallery__item:before {
        padding-bottom: 100%;
      }
    }
  }

  &__leftover-count {

    display:         inline-flex;
    flex-flow:       row;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    color: palette(white);
    font-size: 32px;
    font-weight: $font-weight--bold;

    @include from(lp){
      font-size: rem-calc(52);
    }
  }


  &__item {
    display: block;
    flex: 0 1 50%;
    outline: 0;
    overflow: hidden;
    position: relative;

    &.hide {
      position: absolute;
      left: -9999em;
    }

    img {
      display: block;
      transition: all .4s ease;
      will-change: transform;
      max-width: none;
      width:     100.5%;
    }

    &.is-last {
      color: #FFF;
      font-size: rem-calc(48);
      position: relative;

      span {
        height: 100%;
        width:  100%;
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
      }

      &::after {
        background-color: rgba(palette(black), .7);
        content: '';
        position: absolute;
        top:  0;
        left: 0;
        height: 100%;
        width:  100%;
        z-index: 1;
      }
    }

    &:hover {
      img {
        transform: scale(1.01, 1.01);
      }
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
      width: 100%;
    }

    &:first-child {
      width: 100%;
      flex: 1 1 100%;

      &:before {
        padding-bottom: 50%;
      }
    }
  }
}


// mfp

.mfp-fade.mfp-wrap.mfp-ready {
  &.next {
    .mfp-content {
      opacity: 0;
      transition: all .4s $ease-out-cubic;
      transform: scale(1) translateX(-20%);
    }

    &.mfp-image-loading {
      .mfp-content {
        transform: scale(1) translateX(20%);
        transition: all 0s linear;
      }
    }
  }

  &.prev {
    .mfp-content {
      opacity: 0;
      transition: all .4s $ease-out-cubic;
      transform: scale(1) translateX(20%);
    }

    &.mfp-image-loading {
      .mfp-content {
        transform: scale(1) translateX(-20%);
        transition: all 0s linear;
      }
    }
  }
}
