//
// Pagination
// --------------------------------------------------

.wp-pagenavi {
  @include add-horizontal-spacing;
  text-align: center;
  position: relative;
  margin-top:   0;
  margin-right: auto;
  margin-left:  auto;
  padding-top:    rem-calc(30);
  padding-bottom: rem-calc(40);
  max-width: rem-calc(740);
  width: 100%;

  @include from(lt) {
    padding-right: 0;
    padding-left:  0;
  }

  > * {
    display: inline-block;
    border: 1px solid #ccc;
    line-height: 1;
    font-size: rem-calc(14);
    font-weight: 600;
    margin-right:  rem-calc(5);
    margin-bottom: rem-calc(5);
    padding: rem-calc(13 15);
  }

  .pages {
    display: none;
  }

  .current {
    background-color: #fff;
    color: #a2a8aa;
  }

  a {
    background-color: #fff;
    transition: all .3s ease;

    &:hover {
      border-color: darken(palette(brand), 10%);
      background-color: palette(brand);
      color: #FFF;
    }
  }

  .nextpostslink,
  .previouspostslink {
    background-color: transparent;
    border: 0;
    font-size: rem-calc(40);
    padding: 0;
    vertical-align: bottom;

    &:hover {
      background-color: transparent;
      color: palette(brand, two);
    }
  }
}
