//
// Banner
// --------------------------------------------------

.banner {
  &.content-block {
    @include add-border-frame();
  }

  &__inner {
    position: relative;
    @include add-horizontal-spacing();
    @include add-vertical-spacing($spacing-unit--horz);
    width: 100%;
  }

  &__image {
    position: absolute;
    top:  0;
    left: 0;
    height: 100%;
    width:  100%;
  }

  &__image__inner {
    position: relative;
    height: 100%;
    width: 100%;
    &::before {
      @include overlay(0.35);
    }
  }

  &__content {
    margin-right:   auto;
    margin-left:    auto;
    max-width: rem-calc($max-content-width--wide);
    position: relative;
    z-index: 1;

    .heading,
    .sub-heading,
    .summary {
      color: palette(white);
      text-align: center;
    }

    .summary {
      max-width: rem-calc(500);
      margin-left: auto;
      margin-right: auto;
    }

    p {

      &:first-of-type {
        @include large-text;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__buttons {
    margin-top: rem-calc(40);
    text-align: center;
  }

  &.has-text {
    @include from(pt) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
