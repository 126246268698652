//
// Lists
// --------------------------------------------------

%list-link-style {
  font-family: $font-family--heading;
  font-size: $font-size--h6;
  color: $color--text;
  position: relative;
  display: block;

  &:hover {
    color: $color--hover;
  }
}

%list-container {
  overflow: hidden;
  padding: rem-calc(15 0);

  &:last-child {
    border-bottom: 1px solid $color--border;
  }
}

.list {
  $l: &;

  &__inner {
    @include pie-clearfix;
    margin:  0;
    padding: 0;
  }

  // @TODO: Investigate why this isn't applying
  @include from(lp) {
    &.has-2-columns {
      #{$l}__inner {
        column-count: 2;
      }

      #{$l}__item {
        @include after-break {
          border-top: 1px solid $color--border;
        }
      }
    }
  }

  &__item {
    border-bottom: 1px solid $color--border;
    break-inside: avoid;
    position: relative;
    margin:  0;
    padding: rem-calc(10 0);
    list-style-type: none;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    width: 100%;

    &:first-child {
      border-top: 1px solid $color--border;
    }

    &.has-icon {
      padding: rem-calc($spacer--2 0 $spacer--2 $spacer--6);
    }

    &__content {
      width: 100%;
    }

    &.has-label &__content {
      text-align: right;
      margin-left: auto;
      width: auto;

      @include to(lp) {
        font-size: rem-calc(12);
      }
    }
  }

  &__item--link {
    a {
      outline: 0;
      font-weight: 700;
      padding: rem-calc(0 $spacer--5 0 0);
      width: 100%;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: palette(brand);
    position: absolute;
    top: 50%;
    left: rem-calc(5);
    height: rem-calc(24);
    width:  rem-calc(24);
    transform: translate(0, -50%);
    line-height: 0;
  }
}
