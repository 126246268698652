//
// Transitions
// --------------------------------------------------

%default-transition,
%dft {
  @include transition()
  //will-change: background-color, border-color, color;
}

.lazyload {}

.fade-lazyload {
  @include transition--lazyload();
  //will-change: opacity;
}


.csstransforms3d body:not(.file-upload):not(.donations){
  .scroll-magic{
    &:not(.fade-lazyload) {
      @include transition(#{transform, opacity}, 0.4s, $transitions--easeOutSine);
      opacity: 0 !important;
      will-change: transform, opacity;

      &.scroll-triggered {
        opacity: 1 !important;
        transform: translateX(0) translateY(0) translateZ(0) !important;
      }
    }

    &.fade-lazyload {
      @include transition(#{transform, opacity}, 0.4s, $transitions--easeOutSine);
      opacity: 0 !important;
      will-change: transform, opacity;

      &.scroll-triggered.lazyloaded {
        opacity: 1 !important;
        transform: translateX(0) translateY(0) translateZ(0) !important;
      }
    }

    &--down {
      transform: translateX(0) translateY(-50px);
    }

    &--up {
      transform: translateX(0) translateY(50px);
    }

    &--left {
      transform: translateX(50px) translateY(0);
    }

    &--right {
      transform: translateX(50px) translateY(0);

      @include from(lt) {
        transform: translateX(-50px) translateY(0);
      }
    }
  }
}