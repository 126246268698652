//
// Tiles
// --------------------------------------------------

.two-tile {
  &.content-block {
    @include add-vertical-spacing($spacing-unit--vert);
  }

  &__inner {
    margin-right: auto;
    margin-left:  auto;

    @include from(lt) {
      display: flex;
      align-items: stretch;
      justify-content: center;
    }

    @include from(lt) {
      max-width: rem-calc($max-content-width--xwide);
    }
  }

  &__image {
    margin: 0 auto;
    max-width: rem-calc($max-content-width);
    overflow: visible;
    position: relative;
    padding: 0;
    z-index: 2;

    text-align: center;
    @include from(lp) {
      padding: rem-calc(0 $spacer--8)
    }
    @include from(lt) {
      display: flex;
      align-items: center;
      margin-bottom:  0;
      max-width: none;
      width: 50%;
      padding: rem-calc($spacer--8 0);

      img,
      picture {
        display: block;
        width: 100%;
      }
    }
  }

    &__image__inner {
      position: relative;;
      height: 100%;
      width: 100%;
      padding-bottom: 100%;
      margin-bottom: rem-calc(-$spacer--3);

      @include from(lt) {
        margin-bottom: 0;
      }

      .bg-img {
        width: auto;
        @include from(lt) {
          bottom: 0;
        }
      }
    }

  &__content {
    padding: rem-calc($spacer--10 $spacer--5 $spacer--7);
    position: relative;
    z-index: 1;
    max-width: rem-calc($max-content-width);
    margin: 0 auto;

    @include from(pt) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem-calc($spacer--12 $spacer--5 $spacer--8);
    }

    @include from(lt) {
      max-width: none;
      width: 50%;
      @include add-vertical-spacing;
    }

    .background-color--dark:not(.two-tile--floating-images) & {
      background-color: palette(white);
    }

    .background-color--light:not(.two-tile--floating-images) & {
      background-color: palette(blue, lighter);
    }
  }

    &__content__inner {
      max-width: rem-calc(480);
      margin: 0 auto;
    }

  &--left {
    @include from(lt) {
      .two-tile__image{
          order: 2;
        .bg-img {
          left: rem-calc(-$spacer--3);
        }
      }

      .two-tile__content {
        padding-right: rem-calc($spacer--8);
      }
    }
  }

  &--right {
    @include from(lt) {
      .two-tile__content {
        padding-left: rem-calc($spacer--8);
      }

      .bg-img {
        right: rem-calc(-$spacer--3);
      }
    }
  }

  &--floating-images {

    .two-tile__inner{
      @include from(pt) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-flow: row;
      }
    }

    .two-tile__images {
      flex: 1 1 auto;
      width: 100%;

      @include from(pt) {
        @include add-horizontal-spacing(120, margin, right);
        width: 50%;
      }
      img {
        display: block;
      }
    }

    .two-tile__image {
      padding: 0;

      &:first-child {
        margin-left: 0;
        margin-right: auto;
        margin-bottom: rem-calc($spacer--5);
        width: 48%;
      }

      &:last-child {
        margin-left: auto;
        margin-right: 0;
        width: 83%;
      }
    }

    .two-tile__content {
      padding: 0;
      @include add-vertical-spacing($spacing-unit--vert, padding, bottom);
      width: 100%;
      margin-left: 0;

      @include from(pt) {
        padding-bottom: 0;
        width: rem-calc(480);
        flex: 0 0 rem-calc(480);
      }
    }
    
    .two-tile__content__inner {

      @include to(lp) {
        max-width: none;
      }
    }

    &.two-tile--right .two-tile__content {
      @include from(pt) {
        order: 2;
      }
    }
  }
}