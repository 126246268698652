//
// Standard Text
// --------------------------------------------------

.standard-text {

  &--horizontal {
    @include from(sd) {

      .component__content {
        max-width: rem-calc($max-content-width--xwide);
      }

      .standard-text__inner {
        display:         flex;
        flex-flow:       row;
        justify-content: center;
        align-items:     flex-start;
        max-width: rem-calc(1280);
        margin: 0 auto;
      }

      .content-inner__header {
        flex: 1 1 35%;
        @include add-horizontal-spacing($spacer--12, margin, right);

        text-align: right;

        .heading {
          @include font-styles--alpha();
          line-height: 1.125em;
          margin-bottom: 0;
        }
      }

      .content-inner__body {
        flex: 1 1 65%;

        .summary {
          max-width: rem-calc(640);
          margin-left: 0;
          margin-right: auto;
        }
      }

    }
  }
}