.gform_wrapper {
  .edit {

  }
  .gpnf-nested-entries {
    width: 100%;
  }
  .gpnf-row-actions{
    text-align: right;

    ul {
      display:         inline-flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items:     center;

      @include from(lp) {
        flex-flow: row;
      }
      li:first-child {
        margin-bottom: rem-calc($spacer--1);
        @include from(lp) {
          margin-bottom: 0;
          margin-right: rem-calc($spacer--1);
        }
      }
    }
    svg {
      display: block;
      height: rem-calc($spacer--3);
      width: rem-calc($spacer--3);
      fill: palette(brand);
    }
  }
  .gpnf-add-entry {
    @include button;
    border-color: palette(brand);
    background: transparent;
    color: $color--text;

    svg {
      fill: $color--text;
      margin-top: rem-calc(2);
      margin-left: rem-calc($spacer--1);
      height: rem-calc($spacer--2);
      width: rem-calc($spacer--2);
    }
  }

  .gfield {
      &.accordion {
      margin-bottom: 0;
      margin-top: 0;

      .accordion__heading {
        @include font-styles--zeta();
        letter-spacing: 0;
        padding: rem-calc($spacer--2 $spacer--5 $spacer--2 0);
      }
      .accordion__container  {
        &:first-child {
          border-top: none;
        }
        &:last-child {
          border-bottom: none;
        }
        p {
          font-size: rem-calc(18);
        }
      }
    }
  }


  .review-order {
    .gfield_label .gfield_required {
      display: none;
    }
  }

  .review-order,
  .gfield--checkbox{
    &.gfield_error{
      background-color: palette(red, error);
      padding: rem-calc($spacer--2);
    }
  }


  .accordion + .gfield--form {
    .gfield_label {
      display: none;
    }
  }

  .gfield--instructions {
    margin-top: rem-calc($spacer--3);
  }

}

.gpnf-nested-form {
  .gfield:not(.gfield--fileupload) {
    position: relative;
  }

  .gfield_description {

  }

   .icon-wrap {
      position: absolute;
      bottom:0;
      left:100%;
      height: rem-calc(70);
      width: rem-calc($spacer--3);
      display:         flex;
      flex-flow:       row;
      justify-content: center;
      align-items:     center;
      margin-left: rem-calc($spacer--1);
      svg {
        height: rem-calc($spacer--3);
        width: rem-calc($spacer--3);
        fill: palette(brand);
      }

    }

    .tool-tip {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: -1;
      padding: rem-calc($spacer--2);
      background: palette(white);
      box-shadow: 0 0 10px rgba(0,0,0, 0.15);
      margin-top: 15px;

      &:after {
        @include triangle(30px 15px, palette(white), up);
        content: '';
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: -1px;
        position: absolute;
        z-index: 1;
      }

      &.active {
        z-index: 10;
      }
    }

    .gform_footer{
      text-align: center !important;
    }
}

.gpnf-nested-entries-container .gpnf-nested-entries {
  th {
    padding: rem-calc($spacer--1 $spacer--2);
    background: palette(grey,light);
    line-height: 1;
    //color: palette(white);
  }

  .gpnf-field {
    line-height: 1em;
  }
}

.gform_drop_area{
    padding: rem-calc($spacer--3);
    border: 1px dashed #CCC;
    text-align: center;
    color: palette(grey);
    background-color: rgba(0,0,0,.02);
    border-radius: 4px;

    .gfield_error & {
      border-color: palette(red);
      background-color: palette(red, error);
    }
}


.gform_wrapper span.gform_drop_instructions{
  display: block;
  margin-bottom: rem-calc($spacer--1);
}

.gpnf-dialog.ui-dialog {
  max-width: 100%;
  .ui-dialog-content {
    padding: rem-calc($spacer--4);

    @include from(pt) {
      padding: rem-calc($spacer--8);
    }
  }
}