
@mixin large-text {
  font-size:   17px;

  @include from(pt) {
    line-height: $line-height--condensed;
    font-weight: $font-weight--light;
    font-size:   rem-calc($font-size--large * 0.85);
  }

  @include from(d){
    font-size:   rem-calc($font-size--large);
  }
}

@mixin uppercase {
  letter-spacing: $letter-spacing--large;
  text-transform: uppercase;
}

@mixin font-styles--alpha {
  color: palette(grey, dark);
  font-size: 28px;
  line-height: 1em;

  @include from(pt){
    font-size: rem-calc($font-size--h1 * 0.85);
  }

  @include from(d) {
    font-size: rem-calc($font-size--h1);
  }
}

@mixin font-styles--beta {
  font-size: 28px;
  line-height: 1.125em;

@include from(pt){
    font-size: rem-calc($font-size--h2 * 0.85);
  }

  @include from(d) {
    font-size: rem-calc($font-size--h2);
  }
}

@mixin font-styles--gamma {
  font-size: 22px;

@include from(pt){
    font-size: rem-calc($font-size--h3 * 0.85);
  }

  @include from(d) {
    font-size: rem-calc($font-size--h3);
  }
}

@mixin font-styles--delta {
  font-family: $font-family--body;
  font-size: rem-calc($font-size--h4);
  font-weight: $font-weight--bold;
  letter-spacing: 0.05em;

  @include from(pt) {
    font-size: rem-calc($font-size--h4);
  }
}

@mixin font-styles--epsilon {
  font-size: rem-calc($font-size--h5);
  font-weight: $font-weight--bold;
  letter-spacing: $letter-spacing--large;
  text-transform: uppercase;

  @include from(pt) {
    font-size: rem-calc($font-size--h5);
  }
}

@mixin font-styles--zeta {
  font-size: rem-calc($font-size--h6);
  font-weight: $font-weight--regular;

  @include from(pt) {
    font-size: rem-calc($font-size--h5);
  }
}

@mixin font-styles--body-copy {
  font-size: 17px;


  @include from(pt){
    font-size: rem-calc($font-size--base);
  }
}