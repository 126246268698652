//
// Accordion
// --------------------------------------------------
.accordion-block {
  .component__content {
    max-width: rem-calc($max-content-width--wide);
  }
}

.accordion {
  width: 100%;

  &__handler {
    border-bottom: 1px solid $color--border;
  }

  &__container {
    border-top: 1px solid $color--border;
    padding: 0;

    &:last-child{
      border-bottom: 1px solid $color--border;
    }
  }

  &__heading {
    cursor: pointer;
    margin:  0;
    padding: rem-calc($spacer--3 $spacer--3 $spacer--3 0);

    color: palette(grey);
    @include font-styles--gamma();
    font-weight: $font-weight--bold;
    line-height: 1em;
    position: relative;

    .icon {
      fill: #82888a;
      height: 13px;
      width:  14px;
      position: absolute;
      right: 10px;
      top:   50%;
      transform: translateY(-50%) rotate(180deg);
      transition: transform .3s $ease-in-out-quad;
    }

    &__toggle {
      position: absolute;
      top: 50%;
      right: rem-calc(10);
      transform: translate(0, -50%);
      height: rem-calc(18);
      width:  rem-calc(18);

      &::before, &::after {
        content: '';
        background-color: palette(brand);
        display: block;
        position: absolute;
        top:  50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::before {
        height: 2px;
        width: rem-calc(18);
      }

      &::after {
        height: rem-calc(18);
        width:   2px;
        transition: transform .3s $ease-in-out-quad;
      }
    }

    &.is-active &__toggle {
      &::after {
        transform: translate(-50%, -50%) scaleY(0.01);
      }
    }

    em {
      position: absolute;
      top: rem-calc(34);
      font-size: rem-calc(16);
    }
  }

  &__content {
    display: none;
    position: relative;
    overflow: hidden;
    padding: rem-calc(0 0 $spacer--3);
    @include add-horizontal-spacing($spacer--6, padding, left);

    text-align: left;
    transform: scale(.9);
    transition: transform .3s ease;
    will-change: transform;

    p {
      line-height: 1.8;
      margin-top: 0;
    }

    &.open-content {
      transform: scale(1);
    }
  }
}
