.service-flyout {
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 10;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  @include add-horizontal-spacing();
  background-color: palette(blue, lighter);
  @include transition(transform, 0.5s);

  @include from(lt) {
    @include add-horizontal-spacing($spacer--8);
  }
  .js-flyout-tab-active & {
    transform: translateX(0%);
  }


  &__inner {
    padding-top: rem-calc($spacer--9);
    padding-bottom: rem-calc($spacer--5);
    overflow: hidden;
    width: 100%;
  }

  &__viewport {
    display:         flex;
    flex-flow:       row;
    justify-content: flex-start;
    align-items:     flex-start;
    @include transition(transform, 0.5s);
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem-calc($spacer--3);
    display:         inline-flex;
    flex-flow:       row;
    justify-content: center;
    align-items:     center;
    cursor: pointer;

    font-size: rem-calc(16);
    font-weight: $font-weight--bold;
    letter-spacing: 0.180em;
    text-transform: uppercase;

    svg {
      display: block;
      margin-bottom: 0;
      height: rem-calc($spacer--2);
      width: rem-calc($spacer--2);
      margin-left: rem-calc($spacer--1);
    }
  }

  &__toggles {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     stretch;
    padding-bottom: rem-calc($spacer--6);
  }

  &__toggle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: rem-calc($spacer--2 $spacer--5);
    border-top: 1px solid palette(white);
    flex: 1 1 100%;
    @include from(lp){
      flex: 1 0 50%;
      border-right: 1px solid palette(white);
    }

    background: palette(brand);
    color: palette(white);
    font-size: rem-calc(17);
    font-weight: $font-weight--bold;
    letter-spacing: $letter-spacing--small;
    line-height: 1em;
    text-align: center;
    @include transition();

    &:nth-child(even),
    &:last-child {
      border-right: none;;
    }

    &:first-child {
      border-top: none;
    }

    &:nth-child(2){
      @include from(lp){
        border-top: none;
      }
    }


    &:hover,
    &.js-tab-toggle--active {
      background-color: palette(blue, dark);
    }
  }

  &__tab {
    flex: 1 0 100%;
    height: 100%;
  }

  &__tab {
    padding-bottom: rem-calc($spacer--4);
  }

  &__tab__inner {

    .services--horizontal & {
      display:         flex;
      flex-flow:       row wrap;
      justify-content: flex-start;
      align-items:     flex-start;
      margin-left: rem-calc(-$spacer--6);
      margin-right: rem-calc(-$spacer--6);
    }
  }

  &__tab__section {
    &:not(:last-child){
      margin-bottom: rem-calc($spacer--4);
    }

    .services--horizontal & {
      padding: rem-calc(0 $spacer--6);
    }
  }

  &__tab__heading {
    width: 100%;
    border-bottom: 1px solid $color--border;
    margin-bottom: rem-calc($spacer--3);
    padding-bottom: rem-calc($spacer--3);
  }
}