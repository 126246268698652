//
// Tiled Image  Block
// --------------------------------------------------

.tiled-image-block {
  &.content-block {
    padding: 0;
    @include add-border-frame($spacer--frame-width, false);

    .home & {
      @include add-border-frame-property($spacer--frame-width, padding-top);
      @include add-border-frame-property(-$spacer--frame-width, margin-top);
    }
  }


  &__container {
    position: relative;
    width: 100%;
    background: palette(blue,lighter);
    @include from(lt) {
      &:before {
        display: block;
        content: '';
        padding-bottom: 54.348%;
        width: 100%;
      }
    }
  }

  &__inner {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     flex-start;

    @include from(lt) {
      flex-flow:       row;
      justify-content: center;
      align-items:     stretch;

      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

//
// Tiled Content  Block
// --------------------------------------------------

.tiled-content-block {
  &.content-block {
    padding: 0;
    @include add-border-frame($spacer--frame-width, false);

    .home & {
      @include add-border-frame-property($spacer--frame-width, padding-top);
      @include add-border-frame-property(-$spacer--frame-width, margin-top);
    }
  }

  &__inner {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: flex-start;
    align-items:     stretch;
  }
}

.tiled-wrap{
  position: relative;
  flex: 1 1 percentage(2/3);

  .tiled--content {
    position: relative;
    z-index: 10;

    &,
    .heading,
    .text-link,
    .summary *,
    .button,
    .btn {
      color: palette(white);
      svg {
        fill: palette(white);
      }
    }
  }

  .tiled--image {

    @include from(lt) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      &:before {
        @include overlay(0.4, linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0)));
      }
    }
  }
}


//
// Tiled Content  Block
// --------------------------------------------------


.tiled{
  flex: 1 1 100%;
  position: relative;

  @include from(lt) {
    flex: 1 1 percentage(1/3);
  }

  .summary {
    font-weight: 400;
  }

  .tiled-content-block & {
    @include add-vertical-spacing();
    @include add-horizontal-spacing();

    @include at(pt) {
      flex: 1 1 50%;
    }

    @include from(lt) {
      padding: rem-calc($spacer--8);
      max-width: percentage(1/3);
    }

    @include breakpoint(1090px) {
      padding: rem-calc($spacer-unit * 12) ;
    }

    @include breakpoint(1471px) {
      padding: rem-calc($spacer-unit * 15) ;
    }

    @include from(ld) {
      padding: rem-calc($spacer-unit * 20);
    }

     &:not(:first-child) {

      .content-inner__header,
      .content-inner__body {

        @include from(pt){
          max-width: rem-calc(250);
          margin: 0 auto;
        }
      }
    }

    .bg-img {
      z-index: -1;
    }

    .mq--tab {
      &:before {
        @include overlay(0.4, linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0)));
      }

      @include from(lt) {
        display: none;
      }
    }

    &--image {
      display: none;

      @include from(lt) {
        display: block;
      }
    }

    &--content {

      @include from(lt) {
        &:not(:first-child){
          background-color: palette(blue, lighter);
        }
      }

      @include to(pt) {
        background-color: transparent;

        &,
        .heading,
        .text-link,
        .summary *,
        .button,
        .btn {
          color: palette(white);
          svg {
            fill: palette(white);
          }
        }

      }
    }

    .summary {
      margin-left: 0;
      max-width: 90%;

      @include at(lp) {
        max-width: 50%;
      }

      @include from(pt) {
        max-width: rem-calc(250);
      }
    }
  }

  .tiled-content-block .tiled-wrap &{
    @include from(lt) {
      max-width: none;
      width: 100%;
    }
  }

  .tiled-image-block & {
    @include add-horizontal-spacing();
    @include add-vertical-spacing($spacing-unit--horz);
    border-top: 1px solid palette(white);

    &:first-child {
      border-top: none;
    }

    @include from(lt) {
      @include add-horizontal-spacing();
      @include add-vertical-spacing();
    }


    @include from(lt) {
      border-left: 1px solid palette(white);
      padding: rem-calc($spacer--5);
      &:first-child {
        border-left: none;
      }

    }

    &:before {
      @include overlay(0.2);
      @include transition(opacity, 0.25s);
    }

    &:hover {
      &:before {
        opacity: 0.35;
      }
    }

    &__inner {
      display:         flex;
      flex-flow:       column;
      justify-content: flex-start;
      align-items:     flex-start;
      height: 100%;
      position: relative;
      z-index: 10;

      max-width: rem-calc(400);


      @include from(lt) {
        justify-content: center;
        margin: 0 auto;
      }
    }

    .tiled__content-wrap {
      position: relative;
      padding: 0;

      .no-touchevents & {
        padding-bottom: rem-calc($spacer--5);
      }
    }

    .heading,
    .label,
    .text-link,
    .summary *,
    .button,
    .btn {
      color: palette(white);
      svg {
        fill: palette(white);
      }
    }

    .content-inner__body {
      .no-touchevents & {

        @include to(pt) {
          //Display Jquery toggle slide in small no touch browsers
          display: block !important;
          height: auto !important;
        }

        @include from(lt) {
          display: none;
        }
      }
    }

    .icon--close {
      //position: absolute;
      display: none;

      .no-touchevents & {

        @include from(lt) {

          display: block;
          cursor: pointer;
          transform: rotate(45deg);
          transform-origin: 50% 50%;
          height: rem-calc($spacer--3);
          width: rem-calc($spacer--3);
          opacity: 1;
          fill: palette(white);
          position: absolute;
          bottom: rem-calc($spacer--3);
          left: rem-calc(2);

          @include transition(#{opacity, transform}, 0.5s);

          &.js-active {
            transform: rotate(-45deg);
            opacity: 0;
          }
        }
      }
    }
  }
}