//
// Content Columns
// --------------------------------------------------

.content-columns {
  &.content-block {
    @include add-horizontal-spacing(($spacing-unit--horz - $spacer--6), padding, both);
  }

  > .heading {
    text-align: center;
  }

  &__content {
    max-width: rem-calc($max-content-width--xwide);
    margin: 0 auto;
  }

  &__container {
    @include from(pt) {
      margin-left:  auto;
      margin-right: auto;
      max-width: rem-calc($max-content-width--xwide);
    }
  }

  &__inner {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: center;
    align-items:     flex-start;
  }

  .content-inner__header + .content-inner__body {
    @include content-vertical-spacing(-$spacer--6, margin-top);
  }
}

.content-column {
  @include content-vertical-spacing($spacer--12, margin-top);

  &:first-child {
    margin-top: 0;
  }

  @include from(lp) {
    flex: 0 1 percentage(1/2);
    @include first(2) {
      margin-top: 0;
    }
  }

  @include from(lt) {
    flex: 0 1 percentage(1/3);
    @include first(3) {
      margin-top: 0;
    }
  }

  &__inner {
    margin-left:    auto;
    margin-right:   auto;
    padding: 0 rem-calc($spacer--3);
    max-width: rem-calc(430);
    text-align: center;

  }

  &__image  {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: center;
    margin-bottom: rem-calc(20);

    img {
      display: block;
    }

    &.svg {
      padding: rem-calc(30);
    }

    svg {
      height: rem-calc(100);
      width:  rem-calc(100);
    }
  }

  &__icon {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc($spacer--3);
    max-width: rem-calc(130);

    img {
      display: block;
    }
  }

  &__heading {
    margin-bottom: rem-calc($spacer--2);

    color: palette(grey);
  }

  &__summary {
    max-width: rem-calc(400);
    margin-left: auto;
    margin-right: auto;
  }

  .content-columns.has-2 & {
    @include from(pt) {
      border: 0;
    }
  }

  .content-columns.has-3 & {
    @include from(pt) {
      &:nth-child(3n) {
        border-right: 0;
      }
    }
  }
}
