.single-service .main {
  @include add-horizontal-spacing();
  padding-top: rem-calc($spacer--8);
}

.services {
  text-align: center;

  &__inner {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: center;
    align-items:     flex-start;
    margin: 0 auto;


    .services--vertical & {
      @include from(lt) {
        flex-flow: row;
      }
    }
  }

  &__column {
    padding-left: 0;
    width: 100%;
    margin-top: rem-calc($spacer--8);
    &:first-child {
      margin-top: 0;
    }

    @include from(lt) {

      .services--vertical & {
        margin-left: 0;
        margin-top: 0;
        width: 50%;

        &:nth-child(2) {
          margin-left: rem-calc($spacer--8);
          padding-top: rem-calc($spacing-unit--vert);
        }
      }
    }
  }
}

.service {
  width: 100%;
  margin: rem-calc($spacer--8) auto 0;
  position: relative;
  box-shadow: 0 0 rem-calc(12) rgba(0, 0, 0, 0.16);
  overflow: hidden;
  text-align: left;
  max-width: rem-calc(640);
  background-color: palette(blue, lighter);


  @include from(lt) {
    max-width: rem-calc(768);

    .services__column:first-child &{
      margin-right: 0;
    }

    .services__column:nth-child(2) &{
      margin-left: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &__image {
    position: relative;
    width: 100%;

    &:before {
      display: block;
      content: '';
      padding-bottom: 75%;
      width: 100%;
    }
  }

  &__slider {
    position: relative;
    z-index: 1;
    .swiper-navigation {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 10;
      display:         inline-flex;
      flex-flow:       row;
      justify-content: center;
      align-items:     center;
      padding: rem-calc($spacer--3);

      svg {
        display: block;
        height: rem-calc(40);
        width: rem-calc(40);
        cursor: pointer;

        .arrow,
        .border {
          fill: palette(white);
        }

        &:hover {

          .arrow{
            fill: palette(white);
          }

          .border,
          .background {
            fill: palette(brand);
          }
        }
      }

      .swiper-prev {
        transform: scale(-1);
        margin-right: rem-calc($spacer--1);
      }
    }
  }

  &__content {
    padding: rem-calc($spacer--8) 0;
    //@include add-vertical-spacing();
    position: relative;
    background-color: palette(blue, lighter);
    text-align: left;

    @include from(lp) {
      padding-bottom: rem-calc($spacer--8);
      padding-right: rem-calc($spacer--10);
    }

    .has_pricing & {
      @include to(pp){
        padding-bottom: rem-calc($spacer-unit * 16);
      }
    }
  }

  &__pricing-toggle {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display:         flex;
    flex-flow:       row;
    justify-content: center;
    align-items:     center;
    height: rem-calc($spacer--8);
    width: 100%;

    background-color: palette(brand);
    cursor: pointer;

    @include from(lp) {
      flex-flow:       column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
    
      left: auto;
      top: 0;
      height: auto;
      width: rem-calc($spacer--8);
      padding-top: 0;
    }

    svg {
      display: block;
      fill: palette(white);
      height: rem-calc($spacer--6);
      width: rem-calc($spacer--4);
      margin: rem-calc(0 0 0 $spacer--2);

      @include from(lp) {
        margin: 0 auto rem-calc($spacer--2);
      }
    }
  }

  &__pricing-toggle__label {
    display: block;

    color: palette(white);
    font-size: rem-calc(15);
    font-weight: $font-weight--bold;
    letter-spacing: 0.180em;
    text-transform: uppercase;

    @include from(lp) {
      transform: rotate(90deg);
      transform-origin: 0 100%;
      height: rem-calc($spacer--8);
      display:         inline-flex;
      flex-flow:       row;
      justify-content: center;
      align-items:     center;
      margin-top: rem-calc(-$spacer--4);
      margin-bottom: rem-calc($spacer--12);
      //padding-right: rem-calc($spacer-unit * 16);
    }
  }

  .content-inner__header,
  .content-inner__body {
    @include add-horizontal-spacing();

    @include from(lp) {
      padding: rem-calc(0 $spacer--8)
    }
  }

  .content-inner__header {
    position: relative;
    margin-bottom: rem-calc($spacer--3);
    @include from(lp){
      margin-bottom: rem-calc($spacer--5);
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      top:50%;
      left: 0;
      margin-top: -1px;
      height: 2px;
      width: rem-calc($spacer--4);
      background-color: palette(brand, accent);

      @include from(lp) {
        width: rem-calc($spacer--5);
      }
    }
  }

  .heading {
    @include font-styles--beta();
    margin-bottom: 0;
  }

  .summary {
    //margin-bottom: rem-calc($spacer--5);

    @include to(lp) {
      p {
        //font-size: 17px;
      }
    }
  }

  .link-holder,
  .link-holder .text-link, {
    //margin-bottom: 0;
  }

  .services--vertical & {

  }

  .services--horizontal & {

    @include from(lt) {
      max-width:none;

      .service__inner {
        display:         flex;
        flex-flow:       row wrap;
        justify-content: center;
        align-items:     stretch;
      }

      &:nth-child(even) {
        .service__image,
        .service__slider {
          order: 2;
        }
      }

      .service__image {
        width: 50%;
      }

      .service__slider {
        width: 50%;
        .service__image {
          width: 100%;
        }
      }

      .swiper-container,
      .swiper-wrapper,
      .swiper-slide,
      .service__slider .service__image {
        height: 100%;
      }

      .service__content {
        display:         flex;
        flex-flow:       column;
        justify-content: center;
        align-items:     flex-start;
        width: 50%;
      }

      &__pricing,
      &__pricing__inner {
        @include add-vertical-spacing($spacer--8, padding, bottom);
      }

      &__pricing__inner {
        @include add-vertical-spacing($spacer--8, padding, top);
      }


      .pricing__tab__section {
        display: block;
        margin: 0;
      }
      .pricing__tab__inner {
        display:         flex;
        flex-flow:       row wrap;
        justify-content: flex-start;
        align-items:     flex-start;
      }
    }
  }
}