//
// Navigation
// --------------------------------------------------

$nav-link-color: $color--text;
$nav-link-spacing: rem-calc($spacer--3);
$nav-font-size: rem-calc(18);
$nav-font-weight: $font-weight--bold;



.region--navigation {
  @include from(d) {
    flex: 1 0 auto;
    margin-left: $nav-link-spacing;

    > ul {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      justify-content: center;
    }
  }
}

.nav--desktop {
  @include pie-clearfix;
  list-style-type: none;
  margin:  0;
  padding: 0;

  li {
    margin:  0;
    padding: 0;
  }

  > li {
    display: inline-block;
    position: relative;

    &.active > a {
      color: palette(brand);
    }
  }

  a:not(.button) {
    display: block;
    position: relative;
    padding: 0 ($nav-link-spacing * .6);


    @include link-color($nav-link-color, palette(brand));
    font-size: $nav-font-size;
    font-weight: $nav-font-weight;
    text-align: center;

    @include breakpoint(d) {
      padding: 0 ($nav-link-spacing * .8);
    }

    @include from(ld) {
      padding: 0 $nav-link-spacing;
    }


  }

  // Other Levels
  .sub-menu {
    background-color: palette(white);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
    opacity: 0;
    position: absolute;
    top: rem-calc(50);
    left: 50%;
    margin-left: rem-calc(-200);
    padding: 10px 15px;
    transform: translateY(20px);
    transition: transform .4s ease, opacity .2s ease, visibility .5s ease;
    width: rem-calc(400);
    visibility: hidden;
    z-index: 10;

    &::before {
      @include triangle(30px 15px, palette(white), up);
      content: '';
      left: 50%;
      position: absolute;
      top: -15px;
      transform: translateX(-50%) translateY(100%);
      transition: all .4s ease;
      z-index: 1;
    }

    a {
      border-bottom: 1px solid #ddd;
      padding-top:    10px;
      padding-bottom: 10px;
      text-align: left;

      .nav-in-hero &,
      .nav-in-hero--front .home & {
        @include link-color($nav-link-color);
      }
    }

    li {
      list-style: none;

      &:last-of-type {
        a {
          border-bottom: 0;
        }
      }
    }
  }

  .hover-active {
    .sub-menu {
      opacity: 1;
      transform: translateY(0%);
      visibility: visible;

      &::before {
        transform: translateX(-50%) translateY(1%);
      }
    }
  }

  .menu-item-has-children {
    &.hover-active {
      &::before {
        opacity: 0;
        transform: translateY(-30%) rotateZ(180deg);
      }
    }

    &::before {
      @extend %icon;
      @extend %angle-down;
      color: $color--text;
      font-size: 30px;
      position: absolute;
      bottom: rem-calc(-30);
      left: 50%;
      margin-left: -15px;
      transition: all .5s ease;
      will-change: transform;
      height: rem-calc(30);
      width:  rem-calc(30);
      .header-float &{
        bottom: rem-calc(-22);
      }
    }
  }
}
