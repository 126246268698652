//
// Sidebar
// --------------------------------------------------

.sidebar {
  ul, li {
    margin:  0;
    padding: 0;
    list-style-type: none;
  }

  h3 {
    @extend .beta;
  }

  .widget_categories {
    li a {
      margin-bottom: rem-calc(10);
      text-transform: uppercase;
      letter-spacing: $letter-spacing--medium;
      font-weight: 700;
    }
  }
}
