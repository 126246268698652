//
// Testimonials
// --------------------------------------------------

.testimonials {
  @include add-vertical-spacing;
  align-items: center;
  color: palette(white);
  display: flex;
  justify-content: center;
  position: relative;

  .wrap {
    max-width: rem-calc($max-content-width);
    position: relative;
    text-align: center;
    z-index: 1;

    .content {
      margin-bottom: rem-calc(30);
      font-family: $font-family--heading;
      font-size: rem-calc(32);
      font-style: italic;

      p {
        line-height: 1.5;
      }
    }

    .sender {
      font-family: $font-family--heading;
      letter-spacing: $letter-spacing--medium;
      text-transform: uppercase;
    }

    .name {
      font-weight: $font-weight--bold;
    }
  }
}
