//
// Hero
// --------------------------------------------------

$home-hero-height: 100vh;



.hero {
  position: relative;
  overflow: hidden;
  display: block;
  @include add-border-frame($spacer--frame-width, false);
  color: palette(white);

  &__image {
    position: relative;
    z-index: 0;
    padding-bottom: 65%;
    width: 100%;

    @include from(lp) {
      padding-bottom: 28.261%;
    }
    &:before {
      @include overlay(0.2);
    }
  }


  &__heading {
    @include add-vertical-spacing($spacing-unit--vert / 2, padding, top);
    @include add-border-frame($spacer--frame-width * 2, false);
    @include content-vertical-spacing($spacer--4);
    padding-bottom: rem-calc($spacer--2);

    font-size: rem-calc(32);
    line-height: 1em;
    text-align: center;
    position: relative;

    .hero__image + & {
      position: absolute;
      bottom:0;
      left:0;
      right:0;
      padding-top: 0;
      color: palette(white);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom:0;
      left: 50%;
      margin-left: rem-calc(-$spacer--3);
      height: 2px;
      width: rem-calc($spacer--6);

      background: palette(brand, accent);
    }
  }

  &--home {
    @include add-border-frame($spacer--frame-width, false);

    .hero__inner {
      //@include add-border-frame-property($spacer--frame-width, padding-bottom);
      @include add-vertical-spacing($spacing-unit--vert, padding, bottom);

      @include from(sd) {
        padding-top: 7%;
        padding-left: 7%;
        padding-bottom: calc(7% + #{rem-calc($spacer--frame-width)});
      }
      @include from(d) {
        padding-top: 9%;
        padding-left: 9%;
        padding-bottom: calc(9% + #{rem-calc($spacer--frame-width)});
      }
    }
    .swiper-container {
      margin-right: 0;
    }

    .slide {
      &:before {
        @include overlay(0.25);
      }
    }

    .slide__image {
      position: relative;
      width:100%;

      picture,
      img {
        display: block;
        width: 100%;

        @include at(lp) {
          //max-height: 500px;
        }
      }
    }

    .slide__content {
      position: absolute;
      z-index: 10;
      width: 100%;
      left: 0;
      box-sizing: border-box;

      @include to(lp) {
        @include add-border-frame($spacer--frame-width, false);
        bottom: rem-calc($spacer--11);
      }

      @include between(lp, lt) {
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
      }

      @include between(pt, lt) {
        max-width: 75%;
        @include add-horizontal-spacing($spacing-unit--horz, padding, left);
      }
      @include at(lt) {
        max-width: 55%;
      }

      @include from(sd) {
        top: auto;
        max-width: rem-calc(740);
        bottom: 15%;
      }
    }

    .slide__content__inner {
      margin-left: auto;
      margin-right: 0;
      max-width: rem-calc(530);
      width: 100%;
      text-align: right;
    }

    .heading,
    .summary {
      margin-bottom: rem-calc($spacer--4);
    }

    .heading,
    .summary p,
    .hero__label{
      color: palette(white);
    }

    .summary p {
      font-weight: 400;
      line-height: 1.625em;
    }

    .link-holder {
      display:         flex;
      flex-flow:       row wrap;
      justify-content: flex-end;
      align-items:     stretch;

      @include from(pp) {
        flex-flow:       row;
      }

      .btn,
      .button {
        margin-right: rem-calc($spacer--1);
        &:last-of-type {
          margin-right: 0;
        }

        @include to(tiny) {
          margin-right: 0;
          margin-bottom: rem-calc($spacer--1);
        }

        @include between(pp, lp) {

          &.shop-home,
          &.shop-commercial {
            flex: 1 1 50%;
            padding: 18px 0;

            .icon {
              display: none;
            }
          }

          &.shop-home {
            margin-right: 0;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }

          &.shop-commercial {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 1px solid #0178AA;
            white-space: nowrap;
          }
        }
      }
    }

    .hero__label {
      display: none;

      @include from(pt) {
        display: block;
        position: absolute;
        bottom:0;
        left: 0;
        z-index: 10;
        padding-bottom: rem-calc($spacer--4);

        line-height: 1em;
        font-size: rem-calc(14);
        font-weight: $font-weight--regular;
        text-align: right;
      }

      @include at(pt) {
        width: 75%;
      }

      @include from(lt) {
        text-align: center;
        right: rem-calc(100);
      }
    }

    .swiper-navigation {
      position: absolute;
      right: 0;
      bottom: 0;
      display:         flex;
      flex-flow:       row;
      justify-content: flex-end;
      align-items:     center;
      z-index: 20;
      padding: rem-calc($spacer--2 $spacer--3);

      @include from(lp) {
        padding: rem-calc($spacer--4);
      }

      .swiper-prev {
        transform: scaleX(-1);
        margin-right: rem-calc($spacer--1);
      }

      .circle-arrow {
        display: block;
        height: rem-calc($spacer--5);
        width: rem-calc($spacer--5);
        cursor: pointer;

        .arrow,
        .border,
        .background {
          @include transition(fill);
        }

        .arrow,
        .border {
          fill: palette(white);
        }

        &:hover {
          .border {
            fill: palette(brand);
          }

          .background {
            fill: palette(brand);
          }
          .arrow {
            fill: palette(white);
          }
        }
      }
    }
  }

  &--internal {
    .wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      min-height: rem-calc(320);

      @include from(pt) {
        min-height: rem-calc(400);
      }

      @include from(d) {
        height:     rem-calc(460);
        min-height: 0;
      }

      @include from(ld) {
        height: rem-calc(520);
      }
    }
  }
}
