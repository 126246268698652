.search-results {
  .search {
    @include add-horizontal-spacing();
  }
}

.search-result {
  margin:  rem-calc($spacer--4) auto;
  padding: rem-calc($spacer--4 0);
  border-bottom: 1px solid #CCC;
  max-width: rem-calc($max-content-width);

  a{
    color: $color--text;
  }

  &:last-child {
    border-bottom: 0;
  }
}


