//
// Animation
// --------------------------------------------------

@keyframes spinner {
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
}

// Animate in from the right
@keyframes pulseRight {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  50% {
    transform: translate3d(20%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}

// Animate in from the right
@keyframes inFromRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Animate out to the right
@keyframes outToRight {
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

// Animate in from the left
@keyframes inFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Animate out to the left
@keyframes outToLeft {
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

// Nav slide in down
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}