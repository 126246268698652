.site-notification {
  background-color: palette(brand, accent);
  text-align: center;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  position: relative;
  z-index: 1100;
  width: 100%;

  height: rem-calc($spacer--6);

  &__link {
    display: block;
    text-align: center;

    color: $color--text;
    font-size: rem-calc(18);
    font-weight: 400;
    line-height: 1em;
    text-align: center;
  }
}

.site-notification-enabled {
  .nav__toggle,
  .nav--mobile--primary {
    margin-top: rem-calc($spacer--6);
  }

  .header-float {
    .region--header {
      margin-top: rem-calc(-$spacer--6);
    }

    .nav__toggle {
      margin-top: 0;
    }
  }
}