.store-locator{
  &.content-block {
    @include add-border-frame($spacer--frame-width);
  }

  &__inner {
    @include from(lt) {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-flow: row wrap;
    }
  }

  &__handler {
    position: relative;
    flex: 1 1 100%;
    overflow: hidden;


    @include from(lt) {
      flex: 1 1 auto;
    }

    .map__canvas {
      width: 100%;

      &:after {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 75%;
      }

      @include from(lt) {
        height: 100%;
        &:after {
          display: none;
        }
      }
    }
  }

  &__legend {
    flex: 1 1 100%;
    border: 1px solid $color--border;

    @include from(lt) {
      flex: 0 0 rem-calc(400);
    }
  }

  &__legend__inner {
    @include add-horizontal-spacing();
    @include add-vertical-spacing();
    min-height:  rem-calc(600);
    max-width: rem-calc(528);
    margin: 0 auto;

    @include from(lt) {
      padding: rem-calc($spacer--10 $spacer--8);
    }
  }

  &__heading {
    color: palette(grey);
  }

  .button-dropdown {
    margin-bottom: rem-calc($spacer--6);
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row;
    cursor: pointer;
    width: 100%;
    padding: rem-calc($spacer--2 0);
    @include transition(color, 0.35s);

    border-bottom: 1px solid $color--border;
    font-weight: 700;
    line-height: 1.125em;

    &:first-child {
      border-top: 1px solid $color--border;
    }

    &.js-disabled-trigger {
      color: palette(grey, light);
      svg {
        fill: palette(grey, light);
      }
    }

    svg {
      flex: 0 0 auto;
      height: rem-calc(27);
      width: rem-calc(19);
      margin-right: rem-calc($spacer--2);
      fill: palette(brand);

      @include transition(fill, 0.35s);
    }
  }
}

.store {

  background-color: palette(blue, lighter);

  .store-locator &{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transform: translateX(100%);
    @include transition(transform, 0.75s);
  }

  &.js-active {
    transform: translateX(0%);
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: row;
    position: relative;
    @include add-horizontal-spacing();
    @include add-vertical-spacing();

    @include from(lt) {
      @include add-vertical-spacing($spacer--12);
      @include add-horizontal-spacing($spacer--12);
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem-calc($spacer--3);

    svg {
      fill: palette(grey);
      display: block;
      height: rem-calc(25);
      width: rem-calc(25);
    }
  }
  &__images {
    display: none;

    @include from(pt) {
      display: block;
      @include add-horizontal-spacing($spacer--9, margin, right);
      flex: 1 1 50%;
    }
  }

  &__image {
    margin-top: rem-calc($spacer--3);

    &:first-child {
      margin-top: 0;
    }

    img {
      display: block;
    }
  }

  &__content {

    max-width: rem-calc(400);
    @include from(pt) {
      max-width: none;
      flex: 1 1 50%;
    }
  }

  &__contact-info {

  }

  &__contact-info__detail {

    strong {
      margin-right: rem-calc($spacer--1);
    }
  }

  &__hours {
    margin-top: rem-calc($spacer--3)
  }

  &__hours__label,
  &__hours__line {
    display: block;
  }

  &__hours__label:not(:first-child) {
    margin-top: rem-calc($spacer--3);
  }

  .link-holder {

  }

  .single-location & {

    .store__inner {
      max-width: rem-calc($max-content-width--xwide);
      margin: 0 auto;
    }

    .store__close {
      display: none;
    }
  }
}