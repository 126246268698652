
.post {

  &__inner,
  &__header {
    @include add-horizontal-spacing;
  }

  &__inner{
    padding-top: rem-calc($spacer--8);
    padding-bottom: rem-calc($spacer--8);
  }
  &__header {
    text-align: left;
    @include add-vertical-spacing($spacer--10, padding, top);
    padding-bottom: rem-calc($spacer--3);
  }

  &__header__inner,
  &__image-wrap__inner {
    max-width: rem-calc($max-content-width);
    margin: 0 auto;
  }

  &__heading {
    margin-bottom: rem-calc($spacer--1);
    line-height: 1em;
  }

  &__image-wrap {
    position: relative;
    z-index: 0;
    overflow:hidden;
  }

  &__image-wrap__inner {
    position: relative;
  }

  &__image {
    line-height: 0;
    display: block;

    position: relative;

    &--featured {
      z-index: 2;
      img {
        display: block;
      }
    }

    &--background {
      z-index: 1;
      filter: blur(5px);
      .bg-img {
        left: -10px;
        right: -10px;
        width: auto;
      }
    }
  }
}

.post-meta {
  color: palette(grey);
  font-size: 10px;
  font-weight: $font-weight--bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.alignnone {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.alignleft {
  float: left;
  margin-right: rem-calc($spacer--3);
}

.alignright {
  float: right;
  margin-left: rem-calc($spacer--3);
}

.wp-caption {
  margin-bottom: rem-calc($spacer--3);
  img {
    display: block;
  }
}

.wp-caption-text {
  display: block;
  background: palette(brand);
  color: palette(white);
  padding: rem-calc($spacer--1 $spacer--2);
  font-weight: $font-weight--bold;
}