//
// Loader Overlay
// --------------------------------------------------

.overlay-show {
  @include overlay(0, #003246, fixed, none);
  z-index: -1;

  &:not(.overlay-show--popup){
    @include transition(opacity, 0.5);
  }

  .overlay-visible &,
  &.show {
    pointer-events: all;
    opacity: .5;
    z-index: 1000;
  }

  &--popup{
    pointer-events: all;
  }
}


//
// Loader
// --------------------------------------------------

.loader {
  $l: &;
  // border-left: 1px solid $color--border;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll; // has to be scroll, not auto
  position: fixed;
  top:   0;
  right: 0;
  will-change: transform;
  max-width: rem-calc(580);
  height: 100%;
  width:  100%;
  z-index: 1300;

  &:not(.loader--popup){
    background-color: palette(white);
    transition: transform .4s $ease-out-cubic;
    transform: translateX(101%);
    &.show {
      transform: translate3d(0, 0, 0) scale(1);
    }
  }

  h2,
  h3 {
    color: palette(brand);
    font-size: rem-calc(42);
  }

  &__content {
    padding: rem-calc(40 20);

    @include from(lt) {
      padding: rem-calc(60 50 40 50);
    }
  }

  &__close {
    color: palette(white);
    position: absolute;
    top:   0;
    right: 0;
    font-size: rem-calc(14);
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(70);
    width:  rem-calc(70);
    z-index: 10;

    span {
      margin-right: rem-calc(10);
    }

    svg {
      fill: palette(white);
      height: rem-calc(18);
      width:  rem-calc(18);
    }
  }

  .no-csstransforms & {
    right: -200%;

    &.show {
      right: 0%;
    }
  }

  .img-wrap {
    margin-right:   auto;
    margin-left:    auto;
    padding-bottom: 0%;
    height: rem-calc(300);
    width: rem-calc(300);
  }

  .header {
    margin-top:    rem-calc(30);
    margin-bottom: rem-calc(40);

    .title,
    .sub-title {
      margin-bottom: rem-calc(5);
    }
  }

  &--popup {
    background-color: palette(white);
    color: $color--text;
    top:    50%;
    left:   50%;
    right:  auto;
    bottom: auto;
    transform: translateX(-50%) translateY(-50%) scale(0.8);
    text-align: center;
    opacity: 0;
    height: auto;
    max-width: 80%;
    width:  100%;

    @include from(lp) {
      max-width: rem-calc(480);
    }
    @include from(pt) {
      width: rem-calc(850);
      max-width: 80%;
    }

    #{$l}__close {
      box-sizing: content-box;
      line-height: 0;
      padding: rem-calc(20);
      height: rem-calc(20);
      width:  rem-calc(20);
      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: palette(white);

        @include from(pt) {
          fill: $color--text;
        }
      }
    }

    #{$l}__inner {
      @include from(pt) {
        display:         flex;
        flex-flow:       row;
        justify-content: center;
        align-items:     stretch;
      }
    }

    #{$l}__image {
      position: relative;
      flex: 1 1 50%;

      &:before {
        content: '';
        display: block;
        padding-bottom: 100%;
        width: 100%;
      }
    }

    #{$l}__content {
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      padding: rem-calc($spacer--5 $spacer--5 $spacer--10);

      @include from(pt) {
        padding: rem-calc(($spacer-unit * 16) $spacer--5);
      }

      backface-visibility: hidden;
      background-color: palette(white);
      text-align: left;
    }

    .heading {
      @include font-styles--alpha();
      margin-bottom: rem-calc($spacer--3);
      color: $color--text;
    }

    .summary p {
      @include large-text()
    }

    .link-holder {
      margin-top: rem-calc($spacer--3);
    }

    #{$l}__logo {
      position: absolute;
      bottom: rem-calc($spacer--4);
      right:  rem-calc($spacer--4);
      z-index: 11;
      width: rem-calc(150);
      display: block;
    }
  }
}

.visible-off-canvas {
  display: none;

  .loader & {
    display: block;
  }
}
