//
// Footer
// --------------------------------------------------

.footer {
  @include add-horizontal-spacing();
  @include responsive-vert-unit(padding-bottom, $spacer--frame-width);
  @include responsive-vert-unit(padding-top, $spacer--frame-width);

  @include from(pt) {
    padding-top: 0;
  }

  @include to(pt) {
    padding-bottom: rem-calc($spacer--6);
  }

  &__row {
    &--first {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;

      @include from(pt){
        @include add-vertical-spacing($spacer--12, padding, top);
      }

      @include from(lt) {
        @include add-vertical-spacing($spacer--12, padding, bottom);
        flex-flow: row;
        justify-content: center;
      }
    }

    &--second {
      display:         flex;
      flex-flow:       row wrap;
      justify-content: center;
      align-items:     center;
    }
  }

  .social-media {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: rem-calc($spacer--3);

    @include at(pt){
      margin-top: rem-calc($spacer--6);
    }

    @include from(lt) {
      margin-right: rem-calc($spacer--9);
      width: auto;
    }
  }

  .copyright {
    max-width: rem-calc(1680);
    margin: 0 auto;
    border-top: 1px solid $color--border;
    padding: rem-calc($spacer--2 0);
    width: 100%;

    &,
    a {
      color: $color--text;
      font-family: $font-family--body;
      font-size: rem-calc(14);
      font-weight: $font-weight--bold;
      line-height: 1.25em;
      text-align: center;
    }

    a{
      @include transition();

      &:hover {
        color: palette(brand);
      }
    }
  }

}

.footer-nav {
  display: none;

  @include from(lt) {
    display:         flex;
    flex-flow:       row wrap;
    justify-content: space-between;
    align-items:     stretch;
    width: rem-calc($max-content-width--wide);
    order: 2;
  }

  @include at(lt){
    width: rem-calc($max-content-width);
  }


  &__list {
    flex: 1 1 percentage(1/3);
    padding-left: rem-calc($spacer--3);
    padding-right: rem-calc($spacer--3);
    margin-bottom: 0;
    border-left: 1px solid $color--border;

    &:last-child {
      flex: 0 1 auto;
    }
  }

  &__item {
    list-style: none;
  }

  &__link {
    color: $color--text;
    letter-spacing: $letter-spacing--small;
    font-weight: $font-weight--bold;
    @include transition(#{color, opacity});

    &:hover {
      color: palette(brand);
    }

    .secondary-nav & {
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.separator {
  &--second {
    @include to(pp) {
      display: none;
    }
  }
}

.leap-link {
  @include to(pp) {
   display: block;
  }
}