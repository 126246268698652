//
// Grid Mixins
// --------------------------------------------------
// @TODO: Add component spacing mixins?

$debug-grid: false !default;

@mixin grid-background {
  @if $debug-grid == true {
    @include background-grid;
  }
}

@mixin content-vertical-spacing($unit, $property: 'margin-bottom') {

  $unit--vert--mobile: $unit * $content-spacing-mod--mobile;
  $unit--vert--tab:    $unit * $content-spacing-mod--tab;
  $unit--vert--desk:   $unit * $content-spacing-mod--desk;


  #{$property}: rem-calc($unit--vert--mobile);

  @include from(pt) {
    #{$property}: rem-calc($unit--vert--tab);
  }

  @include from(sd) {
    #{$property}: rem-calc($unit--vert--desk);
  }

  @include from(ld) {
    #{$property}: rem-calc($unit);
  }
}

@mixin responsive-vert-unit($property, $unit) {

  //setup units
  $unit--vert--ldesk:  $unit;
  $unit--vert--desk:  $unit * $spacing-mod--vert--desk;
  $unit--vert--tab:   $unit * $spacing-mod--vert--tab;
  $unit--vert--mobile:$unit * $spacing-mod--vert--mobile;


  #{$property}: rem-calc($unit--vert--mobile);

  @include from(pt) {
    #{$property}: rem-calc($unit--vert--tab);
  }

  @include from(sd) {
    #{$property}: rem-calc($unit--vert--desk);
  }

  @include from(ld) {
    #{$property}: rem-calc($unit--vert--ldesk);
  }
}

@mixin responsive-horz-unit($property, $unit) {

  //setup units
  $unit--horz--desk:  $unit;
  $unit--horz--tab:   $unit * $spacing-mod--horz--tab;
  $unit--horz--mobile:$unit * $spacing-mod--horz--mobile;

  #{$property}: rem-calc($unit--horz--mobile);

  @include from(lt) {
    #{$property}: rem-calc($unit--horz--tab);
  }

  @include from(d) {
    #{$property}: rem-calc($unit--horz--desk);
  }
}

@mixin add-border-frame-property($unit: $spacer--frame-width, $property: 'padding') {

  $frame-width--desk:  $unit;
  $frame-width--tab:   $unit * $spacing-mod--frame--tab;
  $frame-width--mobile: $unit * $spacing-mod--frame--mobile;

  #{$property}: rem-calc($frame-width--mobile);

  @include from(lp) {
    #{$property}: rem-calc($frame-width--tab);
  }

  @include from(lt) {
    #{$property}: rem-calc($frame-width--desk);
  }
}

@mixin add-border-frame($unit: $spacer--frame-width, $has_vertical: 'true') {

  @include add-border-frame-property($unit, 'padding-left');
  @include add-border-frame-property($unit, 'padding-right');

  @if $has_vertical == 'true' {
    @include add-border-frame-property($unit, 'padding-top');
    @include add-border-frame-property($unit, 'padding-bottom');

    .has_frame + &.has_frame {
      @include add-border-frame-property(-$unit, 'margin-top');
    }
  }
}

@mixin _add-horizontal-spacing-right($horizontal-spacing: $spacing-unit--horz, $type: 'padding') {
  $horizontal-spacing--desk:   rem-calc($horizontal-spacing);
  $horizontal-spacing--tab:    rem-calc($horizontal-spacing * $spacing-mod--horz--tab);
  $horizontal-spacing--mobile: rem-calc($horizontal-spacing * $spacing-mod--horz--mobile);

  @if $horizontal-spacing == $spacing-unit--horz / 2 {
    $horizontal-spacing--mobile: rem-calc($spacing-unit--horz * $spacing-mod--horz--mobile);
  }

  #{$type}-right: $horizontal-spacing--mobile;

  @include from(lt) {
    #{$type}-right: $horizontal-spacing--tab;
  }

  @include from(d) {
    #{$type}-right: $horizontal-spacing--desk;
  }
}

@mixin _add-horizontal-spacing-left($horizontal-spacing: $spacing-unit--horz, $type: 'padding') {
  $horizontal-spacing--desk:   rem-calc($horizontal-spacing);
  $horizontal-spacing--tab:    rem-calc($horizontal-spacing * $spacing-mod--horz--tab);
  $horizontal-spacing--mobile: rem-calc($horizontal-spacing * $spacing-mod--horz--mobile);

  @if $horizontal-spacing == $spacing-unit--horz / 2 {
    $horizontal-spacing--mobile: rem-calc($spacing-unit--horz * $spacing-mod--horz--mobile);
  }

  #{$type}-left: $horizontal-spacing--mobile;

  @include from(lt) {
    #{$type}-left: $horizontal-spacing--tab;
  }

  @include from(d) {
    #{$type}-left: $horizontal-spacing--desk;
  }
}

@mixin add-horizontal-spacing($horizontal-spacing: $spacing-unit--horz, $type: 'padding', $direction: 'both') {
  @if $direction == 'right' or $direction == 'both' {
    @include _add-horizontal-spacing-right($horizontal-spacing, $type);
  }
  @if $direction == 'left' or $direction == 'both' {
    @include _add-horizontal-spacing-left($horizontal-spacing, $type);
  }
}

@mixin _add-vertical-spacing-top($vertical-spacing, $type) {
  //setup padding variables if one was provided

  //setup units
  $spacing-unit--vert--ldesk:  $vertical-spacing;
  $spacing-unit--vert--desk:   $vertical-spacing * $spacing-mod--vert--desk;
  $spacing-unit--vert--tab:    $vertical-spacing * $spacing-mod--vert--tab;
  $spacing-unit--vert--mobile: $vertical-spacing * $spacing-mod--vert--mobile;


  #{$type}-top: rem-calc($spacing-unit--vert--mobile);

  @include from(pt) {
    #{$type}-top: rem-calc($spacing-unit--vert--tab);
  }

  @include from(sd) {
    #{$type}-top: rem-calc($spacing-unit--vert--desk);
  }

  @include from(ld) {
    #{$type}-top: rem-calc($spacing-unit--vert--ldesk);
  }
}

@mixin _add-vertical-spacing-bottom($vertical-spacing, $type) {
  //setup padding variables if one was provided

  //setup units
  $spacing-unit--vert--ldesk:  $vertical-spacing;
  $spacing-unit--vert--desk:   $vertical-spacing * $spacing-mod--vert--desk;
  $spacing-unit--vert--tab:    $vertical-spacing * $spacing-mod--vert--tab;
  $spacing-unit--vert--mobile: $vertical-spacing * $spacing-mod--vert--mobile;


  #{$type}-bottom: rem-calc($spacing-unit--vert--mobile);

  @include from(pt) {
    #{$type}-bottom: rem-calc($spacing-unit--vert--tab);
  }

  @include from(sd) {
    #{$type}-bottom: rem-calc($spacing-unit--vert--desk);
  }

  @include from(ld) {
    #{$type}-bottom: rem-calc($spacing-unit--vert--ldesk);
  }
}

@mixin add-vertical-spacing($vertical-spacing: $spacing-unit--vert, $type: 'padding', $direction: 'both') {
  @if $direction == 'top' or $direction == 'both' {
    @include _add-vertical-spacing-top($vertical-spacing, $type);
  }
  @if $direction == 'bottom' or $direction == 'both' {
    @include _add-vertical-spacing-bottom($vertical-spacing, $type);
  }
}

@mixin remove-vertical-spacing-top($vertical-spacing: $spacing-unit--vert) {
  //setup padding variables if one was provided
  $vertical-spacing: $vertical-spacing * -1;

  @include _add-vertical-spacing-top($vertical-spacing, 'margin');

  // @TODO: Update to use vars
  padding-top: rem-calc(40);

  @include from(pt) {
    padding-top: rem-calc(60);
  }
}

@mixin add-spacing($spacing: $spacing-unit--horz, $type: 'padding') {
  $count: length($spacing);

  @if $count == 1 {
    @include add-vertical-spacing($spacing, $type);
    @include add-horizontal-spacing($spacing, $type);
  }
  @else if $count == 2 {
    @include add-vertical-spacing(nth($spacing, 1), $type);
    @include add-horizontal-spacing(nth($spacing, 2), $type);
  }
  @else if $count == 3 {
    @include _add-vertical-spacing-top(nth($spacing, 1), $type);
    @include add-horizontal-spacing(nth($spacing, 2), $type);
    @include _add-vertical-spacing-bottom(nth($spacing, 3), $type);
  }
  @else if $count == 4 {
    @include _add-vertical-spacing-top(nth($spacing, 1), $type);
    @include _add-horizontal-spacing-right(nth($spacing, 2), $type);
    @include _add-vertical-spacing-bottom(nth($spacing, 3), $type);
    @include _add-horizontal-spacing-left(nth($spacing, 4), $type);
  }
}

@mixin add-component-spacing($vertical-spacing: $spacing-unit--component, $type: 'padding') {
  $spacing-unit--component--desk:   $vertical-spacing;
  $spacing-unit--component--tab:    $vertical-spacing * $spacing-mod--component--tab;
  $spacing-unit--component--mobile: $vertical-spacing * $spacing-mod--component--mobile;


  #{$type}: rem-calc($spacing-unit--component--mobile);

  @include from(pt) {
    #{$type}: rem-calc($spacing-unit--component--tab);
  }

  @include from(d) {
    #{$type}: rem-calc($spacing-unit--component--desk);
  }
}

.cf {
  @include clearfix;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

%l-constrained {
  min-width:  rem-calc(320);
  max-width: rem-calc($max-content-width--wide);
}
