//
// Greedy Nav
// --------------------------------------------------

nav.greedy {
  position: relative;
  display: flex;
  align-items: center;

  button {
    align-self: stretch;
    transition: all .4s ease-out;
    outline: 0;
    border: 0;
    background: transparent;
    position: relative;
    padding: rem-calc(0 20 0 0);

    svg {
      height: rem-calc(20);
      width:  rem-calc(20);
      fill: palette(brand, darker);
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.hidden {
      transition: none;
      padding: 0;
      width: 0;
      overflow: hidden;
    }
  }

  ul, li {
    margin:  0;
    padding: 0;
    list-style-type: none;
  }

  ul {
    &.links {
      display: flex;
      justify-content: center;
      flex: 1;
      overflow: hidden;
      font-size: rem-calc(18);

      li {
        flex: none;

        a {
          color: $color--text;
          display: block;
          padding: rem-calc(30 18);
          transition: all .45s ease;

          &:hover,
          &.active {
            color: palette(brand);
          }
        }
      }
    }

    &.hidden-links {
      position: absolute;
      background: darken(palette(brand), 10%);
      top:   100%;
      right: 0;
      padding: 0;
      transform: translateY(0);
      transition: all .35s ease;
      opacity: 1;
      z-index: 10;

      li {
        padding: 0;
      }

      a {
        color: palette(white);
        display: block;
        padding: rem-calc(10 15);
      }

      &.hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
      }
    }
  }
}

.filter-wrap {
  @include add-horizontal-spacing;
  background-color: palette(blue, lighter);
}
.filter {

  text-align: center;

  &__option {
    @include link-color(palette(white));
    display: inline-block;
    font-size: rem-calc(18);
    font-weight: $font-weight--bold;
    text-transform: uppercase;
    position: relative;
    margin: rem-calc(0 15);
    opacity: .8;

    &,
    &::after {
      transition: opacity .2s ease-in-out, transform .2s ease-in-out;
      transition-delay: .2s;
    }

    &::after {
      display: none;
      content: '';
      background-color: palette(brand);
      position: absolute;
      bottom: 0;
      left:   0;
      opacity: 0;
      transform: translateY(-100%);
      height: rem-calc(2);
      width: 100%;
    }

    &.active,
    &:hover {
      opacity: 1;
      color: palette(brand);

      &,
      &::after {
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
        transition-delay: 0s;
      }

      &::after {
        //opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
