//
// Variables
// --------------------------------------------------

// Leap Options
$nav-in-hero: true;


//== Palette
// --------------------------------------------------

// Base colours
$_color-base-grey:  #6C6E72;
$_color-base-brand: #0093D0;
$_color-base-brand--accent: #FFD213;


// Colour Palette
$palettes: (
  white: (
    base: #FFFFFF
  ),
  black: (
    base: #000000,
    brand: darken($_color-base-brand, 54%)
  ),
  blue: (
    base:    $_color-base-brand,
    light:   #E7F1F5,
    lighter: #F5FAFC,
    dark:    #0178AA,
  ),
  red: (
    base: #FF0000,
    error: rgba(#FFCCCC, 0.4)
  ),
  yellow: (
    base:    $_color-base-brand--accent,
    dark:    #EFB30F,
  ),
  grey: (
    base:    $_color-base-grey,
    light:   #E4E6E8,
    dark:    #4D4D4D,
    darker:  #3A3B3B,
  ),
  brand: (
    base:      $_color-base-brand,
    accent:    $_color-base-brand--accent,
  )
);


//== Colours
// --------------------------------------------------

// Typography
$color--text:    palette(grey, dark);
$color--hover:   palette(brand, accent);
$color--border:  #D8D8D8;
$color--link:    palette(brand);


//== Typography
// --------------------------------------------------

// Weights
$font-weight--thin:       100;
$font-weight--light:      300;
$font-weight--regular:    400;
$font-weight--medium:     500;
$font-weight--semi-bold:  600;
$font-weight--bold:       700;
$font-weight--extra-bold: 800;
$font-weight--black:      900;

$font-weight--body:       $font-weight--light;
$font-weight--heading:    $font-weight--bold;

// Families
$_font-family--lato: 'Lato', 'helvetica neue', helvetica, arial, sans-serif;
$_font-family--gotham: 'Gotham', 'helvetica neue', helvetica, arial, sans-serif;

$font-family--body:    $_font-family--lato;
$font-family--heading: $_font-family--gotham;
$font-family--special: $font-family--heading;

// Sizes
$font-size--base:  18px;
$font-size--large: 28px;
$line-height--base: 1.8;
$line-height--condensed: 1.4;

$font-size--p: $font-size--base;
$font-size--h1: 50px;
$font-size--h2: 40px;
$font-size--h3: 32px;
$font-size--h4: 22px;
$font-size--h5: 18px;
$font-size--h6: 18px;

$paragraph-spacing: 30px;

// Letter Spacing
$letter-spacing--base: normal;

$letter-spacing--small:  0.05em;
$letter-spacing--medium: 0.1em;
$letter-spacing--large:  0.2em;

//== Transitions
// --------------------------------------------------

$transitions--easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$transitions--easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$transitions--easeOutBack: cubic-bezier(0.04, 0.29, 0.3, 1.35);
$transitions--base : $transitions--easeOutSine;


//== Hamburger
// --------------------------------------------------
$hamburger-padding-y:                        0px;
$hamburger-layer-width:                     30px;
$hamburger-layer-height:                     2px;
$hamburger-layer-spacing:                    6px;
$hamburger-layer-border-radius:              4px;
$hamburger-layer-color:                      $color--text;
$hamburger-hover-opacity:                    0.7;
$hamburger-hover-transition-duration:        0.125s;
$hamburger-hover-transition-timing-function: $transitions--easeOutSine;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);

// Remove or comment out the hamburger types you don’t want
// or need, so they get excluded from the compiled CSS.
// $hamburger-types: (
//   3dx,
//   3dx-r,
//   3dy,
//   3dy-r,
//   arrow,
//   arrow-r,
//   arrowalt,
//   arrowalt-r,
//   boring,
//   collapse,
//   collapse-r,
//   elastic,
//   elastic-r,
//   emphatic,
//   emphatic-r,
//   slider,
//   slider-r,
//   spring,
//   spring-r,
//   stand,
//   stand-r,
//   spin,
//   spin-r,
//   squeeze,
//   vortex,
//   vortex-r
// );
$hamburger-type: (
  collapse
);

//== Loop helper vars
// --------------------------------------------------

$directions: top left right bottom;
$spacing-prop: margin padding;
$align_prop: left center right;

//== Grid
// --------------------------------------------------

//8 point spacing grid;
$spacer-unit: 8;
$spacer--1: $spacer-unit;     //8
$spacer--2: $spacer-unit * 2; //16
$spacer--3: $spacer-unit * 3; //24
$spacer--4: $spacer-unit * 4; //32
$spacer--5: $spacer-unit * 5; //40
$spacer--6: $spacer-unit * 6; //48
$spacer--7: $spacer-unit * 7; //56
$spacer--8: $spacer-unit * 8; //64
$spacer--9: $spacer-unit * 9; //72
$spacer--10: $spacer-unit * 10; //80
$spacer--11: $spacer-unit * 11; //88
$spacer--12: $spacer-unit * 12; //96

$spacer-unit--smallest: $spacer--3; //24
$spacer-unit--small:    $spacer--6; //48
$spacer-unit--mid:      $spacer--8; //64
$spacer-unit--large:    $spacer--12; //96

// Spacing
$spacer-smallest: rem-calc($spacer-unit--smallest);
$spacer-small:    rem-calc($spacer-unit--small);
$spacer-mid:      rem-calc($spacer-unit--mid);
$spacer-large:    rem-calc($spacer-unit--large);

// Page Spacing Gutter
$spacer--frame-width: $spacer--5;
$spacing-mod--frame--tab: 0.75;
$spacing-mod--frame--mobile: 0.5;
$spacing-mod--frame--tiny: 0.25;


// Set consistent vertical and horizontal spacing units.

$spacing-unit--vert:           216px !default;
$spacing-mod--vert--desk:        0.7 !default;
$spacing-mod--vert--tab:         0.5 !default;
$spacing-mod--vert--mobile:      0.4 !default;
$spacing-unit--vert--small: $spacing-unit--vert / 2;

$spacing-unit--horz:           160px  !default;
$spacing-mod--horz--tab:         0.5  !default;
$spacing-mod--horz--mobile:      0.3  !default;

$spacing-unit--component:       40px !default;
$spacing-mod--component--tab:    1   !default;
$spacing-mod--component--mobile: 1   !default;

$content-spacing-mod--desk:        0.85 !default;
$content-spacing-mod--tab:         0.6 !default;
$content-spacing-mod--mobile:      0.5 !default;

$max-header-width:          560px;

$max-content-width:         730px;
$max-content-width--wide:   800px;
$max-content-width--xwide: 1440px;

$vert-typographic-spacing: 45px;

$noquery: '.lt-ie9';

$grid-padding: 2rem;
$slicer-anti-overlap-corrections: -1px;

// Breakpoints
$slicer-breakpoints:  0        360px       550px       768px       960px       1025px      1367px      1650px      1921px;

//                    └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘
// Slice numbers:          1           2           3           4           5           6           7           8
$slicer-breakpoint-names: 'tiny'      'pp'        'lp'        'pt'        'lt'       'sd'        'd'         'ld'        'vld';
