%list-container {
  overflow: hidden;
  padding: rem-calc(10 0);
}

.entry-content,
.page__content,
.component__content,
.constrained {
  margin-right: auto;
  margin-left:  auto;
  position: relative;
  max-width: rem-calc($max-content-width);
  z-index: 2;
}

.entry-content,
.page__content,
.component__content {
  @include pie-clearfix;

  &.full-width {
    @include add-horizontal-spacing;
    max-width: none;
  }

  .link-holder {
    margin-top: rem-calc($spacer--5);

    .btn {
      &:not(:last-child) {
        margin-right: rem-calc($spacer--2);
      }
    }
  }

  &.wide,
  &--wide {
    max-width: rem-calc($max-content-width--wide);
  }
}

.content-block {
  @include add-vertical-spacing;
  @include add-horizontal-spacing;

  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  $positions: top bottom;
  $sizes: 25 50 75 100;
  @each $size in $sizes{
    &.background-size--#{$size} {
      &:before {
        height: #{$size + %};
      }
    }
  }
  @each $position in $positions{
    &.background-position--#{$position}-0 {
      &:before {
        #{$position}: 0;
      }
    }
    &.background-position--#{$position}-25 {
      &:before {
        #{$position}: 25%;
      }
    }
  }


  &.background-position--align-header {


    padding-top: 0 !important;


    &:before {
      height: auto;
      z-index: -2;
      top: 0;
    }
    &:after{
      background-color: palette(white);
      top:0;

      height: rem-calc($spacer--2 + 28px);
      @include from(pt) {
        height: rem-calc($spacer--2 + $font-size--h1 * 0.85);
      }

      @include from(d) {
        height: rem-calc($spacer--2 + $font-size--h1);
      }
    }
  }

  &.background-color--dark {
    &:before {
      background-color: palette(blue, light);
    }
  }

  &.background-color--light.padding-collapse + .background-color--light.padding-collapse--all,
  &.background-color--dark.padding-collapse + .background-color--dark.padding-collapse--all {
    @include remove-vertical-spacing-top;
  }

  &.background-color--light.padding-collapse + .background-color--light.padding-collapse--half,
  &.background-color--dark.padding-collapse + .background-color--dark.padding-collapse--half {
    margin-top: 0;
    padding-top: 0;
  }

  &.remove-padding {
    @include remove-vertical-spacing-top;
  }

  @each $prop in $spacing-prop{
    @each $direction in $directions {
      &.no-#{$prop}-#{$direction} {
        #{$prop}-#{$direction}: 0;
      }
    }
  }

  @each $prop in $align_prop{
    &.text-aligned--#{$prop} {
      text-align: #{$prop};
    }
  }

  &.image-grid,
  &.map {
    padding: 0;
  }

  &:last-child:not(.background-color--dark):not(.content-block-404) {
    padding-bottom: 0 !important;
  }

}

.iframe-embed-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.125%;
  margin-bottom: rem-calc($spacer--4);

  iframe {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
}

.main,
.sidebar {
  .home &,
  .page & {
    margin-top:    0;
    margin-bottom: 0;
  }
}

.heading {
  @include font-styles--alpha();
  position: relative;
  @include content-vertical-spacing($spacer--6);

  .text-aligned--center & {
    margin-left: auto;
    margin-right: auto;
  }

  .text-aligned--right & {
    margin-left: auto;
    margin-right: 0;
  }

  .accordion-block &,
  .list &,
  .standard-text--vertical &,
  .slider & {
    max-width: rem-calc($max-header-width);
  }

  .accordion-block &,
  .list &,
  .standard-text--vertical &,
  .two-tile--floating-images &,
  .slider &,
  .contact__content & {
    display: inline-block;
    padding-top: rem-calc($spacer--2);

    &:before {
      content: '';
      display: block;
      position: absolute;
      top:0;
      height: 2px;
      width: rem-calc($spacer--6);

      background: palette(brand, accent);
    }
  }

  .content-columns .component__content & {
    @include content-vertical-spacing($spacer--12);
  }

  .banner & {
    margin-bottom: rem-calc($spacer--4);
  }

  .tiled &,
  .two-tile:not(.two-tile--floating-images) & {
    @include font-styles--beta();
    margin-bottom: rem-calc($spacer--4);
    @include content-vertical-spacing($spacer--4);
  }

  .two-tile:not(.two-tile--floating-images) & {
    max-width: rem-calc(340);
  }


  .slider & {
    @include content-vertical-spacing($spacer--4);
  }

  &--section {
    position: relative;
    padding-top: rem-calc($spacer--2);
    padding-bottom: rem-calc($spacer--2);
    @include content-vertical-spacing($spacer--7);

    @include font-styles--gamma();
    color: palette(grey);
    line-height: 1em;
    text-align: center;

    &:after{
      content: '';
      display: block;
      background: palette(brand, accent);
      height: 2px;
      width: rem-calc(50);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  &--faq {
    @include font-styles--delta();
    letter-spacing: 0;
    margin-bottom: 0;
    padding-top: 0;
    margin-top: rem-calc($spacer--1);

    &:before {
      display: none !important;
    }
  }

  .store-locator & {
    @include font-styles--gamma();
    color: palette(grey);
    margin-bottom: rem-calc($spacer--4);
  }
}

.sub-heading {

  .two-tile & {
    font-size: rem-calc($font-size--h4);
    margin-bottom: rem-calc($spacer--3);
  }
}

.label {
  margin-bottom: rem-calc($spacer--1);
}

.summary {

  a:not(.btn):not(.button):not(.text-link) {
    @include transition();
    @include link-color($color--link);
  }

  .accordion-block &,
  .list &,
  .slider &,
  .standard-text--horizontal &,
  .two-tile--floating-images &,
  .content-columns .component__content & {
    p:first-of-type {
      @include large-text;
    }
  }

  .accordion-block & {
    max-width: rem-calc($max-content-width);
  }

  .accordion-block &,
  .list &,
  .content-columns & {
    @include content-vertical-spacing($spacer--12);
  }

  .banner & {
    margin-bottom: rem-calc($spacer--6);
  }

  .tiled & {
    margin-bottom: rem-calc($spacer--4);
  }
}

//If the buttons wrap this add's spacing;
.link-holder {
  @include content-vertical-spacing($spacer--5, 'margin-top');
  margin-bottom: rem-calc(-$spacer--3);

  .btn,
  .button,
  .text-link {
    margin-bottom: rem-calc($spacer--3);
    margin-right: rem-calc($spacer--3);
    &:last-of-type {
      margin-right: 0;
    }
  }

  .two-tile--floating-images & {
    @include content-vertical-spacing($spacer--9, 'margin-top');
  }
}