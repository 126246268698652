//
// Media
// --------------------------------------------------

img, media {
  height: auto;
  max-width: 100%;
}

svg {
  max-height: 100%;
  max-width: 100%;
}

figure {
  margin: 0;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  height: auto;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top:  0;
    left: 0;
    height: 100%;
    width:  100%;
  }
}

#map-canvas {
  //height: 520px;

  img {
    max-width: none;
  }

  @include to(pt) {
    //height: 400px;
  }
  @include to(lp) {
    //height: 300px;
  }
}
