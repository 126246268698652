//
// General
// --------------------------------------------------

* {
  &,
  &::before, &::after {
    box-sizing: inherit;
  }
}

html {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width:  100%;
}

body {
  background-color: palette(white);

  &.nav-is-visible {
    overflow: hidden;
  }
}

.container-content-height {
  @include add-horizontal-spacing;
  position: absolute;
  top:    0;
  right:  0;
  bottom: 0;
  left:   0;
  text-align: center;
  z-index: 100;

  h2 {
    text-align: center;
  }
}

.content-color-light {
  * {
    border-color: palette(white);
    color: palette(white);
  }

  .btn {
    border-color: palette(white);
    color: palette(white);

    &:hover {
      background: $color--hover;
      border-color: $color--hover;
    }
  }
}

.bg-img {
  background: {
    position: center center;
    repeat: none;
    size: cover;
  }
  position: absolute;
  top:    0;
  right:  0;
  bottom: 0;
  left:   0;
  height: 100%;
  width:  100%;
  z-index: 0;
}

.img-wrap {
  padding-bottom: 70%;
  position: relative;
  overflow: hidden;
}


#loaded {
  height: 1px;
}

.circle-arrow {
  cursor: pointer;
  
  .background,
  .arrow,
  .border {
    @include transition(fill);
  }

  .background {
    fill: transparent;
  }
  .arrow,
  .border {
    fill: $color--text;
  }
}

.word-break{
  &--pmobile {
    display: block;
    @include from(lp) {
      display: none;
    }
  }
}
.js-lazy-img-parent {
  display: block;
}

.lp_desktop #lpChat>.lp_minimized,
.lp_desktop #lpChat>.lp_maximized {
  right: 0 !important;
}