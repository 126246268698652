//
// Buttons
// --------------------------------------------------

.button,
.btn {
  @include button;
}

.button-wrap {
  padding-top: 0;
  text-align: center;
}

.buttons {
  .button-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .button,
  .btn {
    flex: 1 0 auto;
    margin-right:  rem-calc(10);
    margin-bottom: rem-calc(10);
    outline: 0;

    &:last-of-type {
      margin-right: 0;
    }

    @include to(pt) {
      flex: 1 0 135px;
    }
  }
}

.text-link {
  display:         inline-flex;
  flex-flow:       row;
  justify-content: flex-start;
  align-items:     center;

  color: $color--text;
  font-size: 16px;
  font-weight: $font-weight--bold;
  letter-spacing: $letter-spacing--small;

  @include from(lt) {
    font-size: rem-calc(18);
  }

  svg {
    display: block;
    margin-top: 3px;
    margin-left: 8px;
    width: 24px;
    height: 12px;

    @include from(pt) {
      margin-top: rem-calc(2);
      margin-left: rem-calc($spacer--1);
      width: rem-calc(30);
      height: rem-calc(15);
    }
  }

  &:hover {
    svg {
      animation: pulseRight .75s $transitions--easeOutSine infinite;
    }
  }

  &--white {
    color: palette(white);

    svg {
      fill: palette(white);
    }
  }
}

.button-dropdown{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  cursor: pointer;

  @include from(pp) {
    min-width: rem-calc(320);
  }

  &__options{
    position: absolute;
    margin-top: 5px;
    top: 100%;
    box-shadow: 0px 0px 5px  rgba(0,0,0,0.15);
    left: 0px;
    width: 100%;
    z-index: 800;
    padding:0 rem-calc(0);


    opacity: 0;
    visibility: hidden;
    transform: translateY(10%);
  }

  &__label{
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    width: 100%;
  }

  svg{
    display: block;

    width: rem-calc(25);
    height: rem-calc(25);;
    margin-left: rem-calc(5);
    margin-right: rem-calc(-10);
    max-width: none;
    max-height: none;
  }

  &__link{
    @include transition();
    display:         flex;
    flex-flow:       row;
    justify-content: center;
    align-items:     center;
    padding: rem-calc(20 25);
    cursor: pointer;

    color: $color--text;
    border-top: 1px solid #dcdfe2;
    background: palette(white);
    font-size: rem-calc(17);
    line-height: 1.125em;

    //height: rem-calc(70);

    &:hover{
      color: palette(brand);
    }

    &:first-child{

      position: relative;
      border-top: none;

      &:before{
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;

        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;

      }
    }
  }



  &.hover-active{
    .button__options{
      transform: translateY(0%);
      opacity: 1;
      visibility: visible;
      z-index: 800;
    }
  }
}