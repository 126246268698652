//
// Social
// --------------------------------------------------

.social-media {
  display: flex;
  flex-flow: row nowrap;

  &__link {
    margin-right: rem-calc(10);
    height: rem-calc(24);
    width:  rem-calc(24);

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      svg {
        fill: palette(brand);
      }
    }
  }

  svg {
    max-height: 100%;
    max-width:  100%;
    @include transition(fill);

    &:hover {
      fill: palette(brand);
    }
  }
}