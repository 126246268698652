//
// Search
// --------------------------------------------------

.text-search {
  display:         flex;
  flex-flow:       row;
  justify-content: flex-start;
  align-items:     center;

  &__form {
    display:         flex;
    flex-flow:       row;
    justify-content: flex-start;
    align-items:     center;
    padding: 0 $spacer--2 + px;

    @include breakpoint($tiny_phone) {
      padding: 0 $spacer--4 + px;
    }
  }

  &__field {
    border: none;
    position: relative;
    z-index: 1;

    padding-left: 0;
    max-width: 70px;
    color: $color--text;
    font-weight: 300;
    @include transition(#{border, max-width});

    @include from(sd) {
      max-width: rem-calc(75);
    }

    &::placeholder {
      color: #a8a8a8;
    }
    &,
    &::placeholder {
      font-weight: 400;
      font-family: $font-family--body;
    }

    &.active,
    &:focus {
      border-bottom: 1px solid palette(brand);
      max-width: 200px;
    }
  }

  &__submit {
    border:none;
    background-color: transparent;

    display: block;
    padding: 10px 0 10px 10px;
    transform: translateX(-100%);
    position: relative;
    z-index: 2;

    @include from(sd) {
      padding: rem-calc(10 0 10 10);
    }

    &:focus{
      outline: none;
    };
  }

  svg {
    display: block;
    height: 15px;
    width: 15px;
    margin: 0 auto;

    @include from(sd) {
      height: rem-calc(15);
      width: rem-calc(15);
    }

    fill: #a8a8a8;
  }

  .nav--mobile & {
    position: absolute;
    top: 0;
    left: 0;
    right: $hamburger-layer-width;
    height: $header-height-mobile;
  }
  .footer-nav__item & {
    display: block;

    .text-search__form {
      padding: 0;
    }

    .text-search__field {
      padding: 0;

      &,
      &::placeholder {
        color: rgba(#4d4d4d, 0.6);
        font-weight: 700;
        line-height: 1.8em;
      }

      &.active,
      &:focus {
        max-width: rem-calc(125);
      }
    }

    svg {
      fill: rgba(#4d4d4d, 0.6);
    }
  }
}


.search-feature {
  @include clearfix;
  background: rgba(palette(black), .8);
  display: none;
  position: fixed;
  top: 0;
  opacity: 0;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  @include add-horizontal-spacing;
  height: 100%;
  width:  100%;
  z-index: 1500;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width:  100%;
  }

  .nocsstransform & {
    left: -200%;
  }

  &__form {
    position: relative;
    margin-right: auto;
    margin-left:  auto;
    max-width: rem-calc(700);
    height: rem-calc(80);
    opacity: 0;
  }

  &__search[type='search'] {
    appearance: none;
    border: 0;
    border-bottom: 3px solid white;
    border-radius: 0;
    box-sizing: border-box;
    background: transparent;
    color: white;
    box-shadow: none;
    display: inline-block;
    margin: 0;
    padding: rem-calc(20 50 20 15);
    float: left;
    font-family: $font-family--heading;
    font-size: rem-calc(30);
    font-weight: 700;
    outline: 0;
    height: rem-calc(80);
    width: 100%;

    @include from(pt) {
      font-size: rem-calc(50px);
    }
  }

  &__submit,
  &__close {
    appearance: none;
    border: 0;
    display: block;
    margin:  0;
    padding: 0;
    font-size: rem-calc(45);
    height: rem-calc(40);
    outline: none;

    i {
      &::before {
        display: block;
        width: 100%;
      }
    }
  }

  &__submit {
    background: transparent;
    color: #FFF;
    position: absolute;
    right: 0;
    font-size: rem-calc(52);
    height: 100%;
  }

  &__close {
    background: transparent;
    color: palette(white);
    position: absolute;
    top: rem-calc(20);
    right: 0;
    transform: translateX(-50%);
    width: rem-calc(50);
  }
}

// Search results
main {
  .search {
    > header {
      text-align: center;
      padding-top:    rem-calc(35);
      padding-bottom: rem-calc(35);

      h3 {
        font-size: rem-calc(20);
      }
    }
  }
}
