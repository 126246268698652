 //
// Header
// --------------------------------------------------

$header-height-default: rem-calc(120);
$header-height-mobile:  rem-calc(100);
$header-height-float:   0.7;
$header-background:     palette(white);
$header-nav-mq:         1440px;

$logo-width-default:    rem-calc(231.65);
$logo-width-mobile:     rem-calc(200);



.hamburger {
  outline: 0;
}

.float-wrapper,
.region--header {
  height: $header-height-mobile;
  width:  100%;

  @include breakpoint($header-nav-mq) {
    height: $header-height-default;
  }

  // option
  .nav-in-hero &,
  .nav-in-hero--front .home & {
    position: absolute;
    top:  0;
    left: 0;
    width: 100%;
  }
}

.nav__toggle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top:   0;
  right: 0;
  z-index: 1100;
  @include transition(all, .3s);

  height: $header-height-mobile;

  @include from(d) {
    height: $header-height-default;
  }

  &__trigger{
    @include add-border-frame($spacer--frame-width, false);
    display: block;
  }

  .admin-bar:not(.header-float) & {
    margin-top: 46px;

    @include breakpoint(782px) {
      margin-top: 32px;
    }
  }

  &:focus,
  &__trigger:focus{
    outline: 0;
  }

  .hamburger-box {
    display: block;
    vertical-align: middle;
  }

  .header-float & {
    position: fixed;
    transform: translateY(-100%);

    &.header-up,
    &.header-down {
      height: $header-height-mobile * $header-height-float;
      @include from(sd) {
        height: $header-height-default * $header-height-float;
      }
    }

    &.header-up {
      transform: translateY(-100%);
    }

    &.header-down {
      transform: translateY(0%);
    }
  }

  .overlay-visible & {
    &.header-up,
    &.header-down {
      height: $header-height-mobile;

      @include from(sd) {
        height: $header-height-default;
      }
    }
  }

  @include breakpoint($header-nav-mq) {
    display: none;

    .nav-hamburger & {
      display: flex;
    }
  }
}


.region--header {
  @include add-border-frame();
  position: relative;
  @include transition(#{background-color, height}, 0.3s, $transitions--base);
  z-index: 100;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: $header-background;

  .nav-in-hero &,
  .nav-in-hero--front .home & {
    background-color: transparent;
    border: 0;
  }

  .header-float & {
    background-color: $header-background;
    border-bottom: 1px solid palette(grey, light);
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0s linear;
    height: $header-height-mobile * $header-height-float;

    @include from(sd) {
      height: $header-height-default * $header-height-float;
    }

    .site-notification-enabled & {
      margin-top: rem-calc(-$spacer--6);
    }

    &.header-up,
    &.header-down {
      transition: transform .4s $ease-out-cubic;
    }

    &.header-up {
      transform: translateY(-100%);
    }

    &.header-down {
      transform: translateY(0%);
    }
  }

  .admin-bar.header-float & {
    &.header-up,
    &.header-down {
      margin-top: -46px;

      @include breakpoint(782px) {
        margin-top: -32px;
      }
    }
  }

  .hamburger-box {
    vertical-align: middle;
  }

  .logo {
    display: block;
    max-height: 100%;
    width: $logo-width-mobile;

    @include from(d) {
      width: $logo-width-default;
    }

    svg {
      fill: palette(white);
      height: auto;
      max-width: 100%;
    }
  }

  .desk {
    display: none;
  }

  .meta,
  .region--navigation {
    display: none;

    @include breakpoint($header-nav-mq) {
      display: block;
    }
  }

  @include breakpoint($header-nav-mq) {
    .region--navigation {
      flex: 1 0 auto;

      > ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .desk {
      display: block;
    }

    .meta {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;

      .icon-search {
        height: rem-calc(20);
        width:  rem-calc(20);
      }
      .button {
        margin-left: rem-calc(15);
      }
    }
  }

  @include from(ld) {
    .search {
      margin-right: rem-calc(40);
    }
  }
}

.admin-bar.header-float {
  .region--header {
    &.header-up,
    &.header-down {
      margin-top: -46px;

      @include breakpoint(782px) {
        margin-top: -32px;
      }
    }
  }
}
