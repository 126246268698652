//
// Blog
// --------------------------------------------------

body.blog,
body.archive {
  .main {
    background-color: palette(grey, lighter);
  }
}

.posts {
  @include add-horizontal-spacing();
  @include add-vertical-spacing($spacing-unit--vert/2);
  text-align: left;
  &__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;

    max-width: rem-calc($max-content-width);
    margin: 0 auto;
  }
}
