$trans-property: background-color, color, border-color !default;
$trans-duration: .25s !default;
$trans-ease: $transitions--easeOutSine !default;

@mixin transition($property: $trans-property, $duration: $trans-duration, $ease: $trans-ease) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $ease;
}

@mixin transition--lazyload($loadclass: 'lazyloaded') {
  @include transition(opacity, 0.25s, $trans-ease);
  opacity: 0;

  &.#{$loadclass} {
    opacity: 1;
  }
}