//
// Tile Slideshow
// --------------------------------------------------

.tile-slideshow-handler {
	position: absolute;
	top:    0;
	bottom: 0;
	width: 100%;

	.content-align-left & {
		right: 0;
	}

	.content-align-right & {
		left: 0;
	}

	.tile-slideshow,
	.slick-track,
	.slick-list,
	.slide-item {
		height: 100%;
		width:  100%;
	}

	.slide-item {
		position: relative;
	}
}

.slick-dots {
	position: absolute;
	right:  0;
	bottom: 4%;
	left:   0;
	margin:  0 auto;
	padding: 0;
	text-align: center;

	li {
		display: inline-block;
		list-style: none;
		margin: rem-calc(0 0 0 15);
		padding: 0;

		&:first-child {
			margin-left: 0;
		}

		button {
			background: palette(white);
			color: transparent;
			border: 1px solid palette(brand);
			border-radius: rem-calc(200);
			display: block;
			margin:  0;
			padding: 0;
			height: rem-calc(15);
			width:  rem-calc(15);

		}
		&.slick-active {
			button {
				background: palette(brand);
			}
		}
	}
}
