@mixin pie-clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin clearfix {
  overflow: hidden;
}

@mixin squish-text {
  font: 0 / 0 serif;
  text-shadow: none;
  color: transparent;
}

// This mixin can be used to set the object-fit:
// @include object-fit(contain);
// or object-fit and object-position:
// @include object-fit(cover, top);

@mixin object-fit($fit: fill, $position: null) {
  object-fit: $fit;

  @if $position {
    object-position: $position;
  	font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
  	font-family: 'object-fit: #{$fit}';
  }
}

// works for up to 50 items
@mixin after-break {
  @for $i from 1 through 50 {
    &:nth-child(#{$i + 2}):nth-last-child(#{$i}),
    &:nth-child(#{$i + 2}):nth-last-child(#{$i + 1}) {
      @content;
    }
  }
}

@mixin teaser($selector: null) {
  $t: null;

  @if $selector != null {
    $t: $selector;
  } @else {
    $t: kill-variant(&);
  }

  @include pie-clearfix;
  color: $color--text;
  display: block;
  margin-bottom: rem-calc(20);
  padding: rem-calc(0 10);
  transition: opacity .3s ease-out, transform .3s ease-out;
  width: 100%;

  &.lazyload {
    opacity: 0;
    transform: translateY(5%);
  }

  &.lazyloaded {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity .3s ease-out, transform .4s ease-out;
  }

  #{$t}__inner {
    background-color: palette(white);
    display: block;
    height: 100%;
  }

  #{$t}__image {
    overflow: hidden;
    position: relative;

    img {
      display: block;
      transition: transform .2s ease-in-out;
      transition-delay: .1s;
      height: auto;
      width:  100%;
    }

    &::after {
      background-color: transparent;
      content: '';
      transition: all .4s ease-in-out;
      position: absolute;
      top:  0;
      left: 0;
      height: 100%;
      width:  100%;
    }
  }

    time {
      font-size: rem-calc(14);
      font-weight: 700;
      letter-spacing: $letter-spacing--medium;
      text-transform: uppercase;
      opacity: .5;
    }

  #{$t}__title {
    font-size: rem-calc(22);
    font-weight: $font-weight--semi-bold;
    text-transform: none;
  }

  #{$t}__content {
    padding: rem-calc(20 30);

    @include from(sd) {
      padding: rem-calc(40 60 50);
    }
  }

  #{$t}__summary {
    &,
    p {
      font-size: rem-calc(16);
      line-height: 1.5;
    }
  }

  &:hover #{$t}__image {
    img {
      transform: scale(1.03);
      transition: transform .3s ease-in-out;
    }

    &::after {
      background-color: rgba(palette(brand), .5);
    }
  }

  @include from(lp) {
    width: percentage(1/2);
  }

  @include from(sd) {
    width: percentage(1/3);
  }

  @include from(d) {
    width: percentage(1/4);
  }
}