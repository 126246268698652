.lt-ie9 {
  .wrap {
    min-width: 1026px;
  }

  .alert-warning {
    border: 4px solid red;
    text-align: center;
    font-size: 18px;
    padding: 40px;
  }

  #mobile-primary-nav,
  .mobile-nav-trigger {
    display: none;
  }

  .l-region--header {
    .l-region--navigation {
      width: 630px;
    }
    .wrap > * {
      top: 30%;
    }
  }

  .hero--home {
    height: 900px;

    .wrap {
      top: 30%;
    }
  }
}

.is-oldIE {
  .hero--home .hero__inner {
    padding-bottom: 9%;
  }
}